import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Program, { ProgramModel } from '../../components/Program';
import back from '../../assets/back.svg';
import { RoutesEnum } from '../routes';
import ProgramsPageWrapper from './ProgramsPageWrapper';
import { User } from '../../types';

function SavedPrograms({ user }: { user: User }) {
  const navigate = useNavigate();

  const [programs, setPrograms] = useState([] as ProgramModel[]);

  useEffect(() => {
    setPrograms(user.savedPrograms || []);
  }, [user]);

  return (
    <ProgramsPageWrapper user={user}>
      <div className="programs-container list">
        <div className="title d-flex justify-content-between">
          <h1>Your Saved Programs</h1>
          <img alt="Back" src={back} onClick={() => navigate(RoutesEnum.Home)} />
        </div>
        <div className="programs">
          {programs.map((program) => {
            return <Program key={program.unique} program={program} user={user}></Program>;
          })}
        </div>
      </div>
    </ProgramsPageWrapper>
  );
}

export default SavedPrograms;
