import PageWrapper from '../PageWrapper';
import { Button, Form } from 'react-bootstrap';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import './Introduction.scss';
import { validateEmail } from '../../Auth/Validation';
import { useNavigate } from 'react-router-dom';
import { getUser, UserInfoRoutes } from '../UserInfo';
import { RoutesEnum } from '../../routes';
import { doc, updateDoc } from 'firebase/firestore';
import { auth, db, dbUser } from '../../../firebase';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { statesList } from './states';
import { updateEmail } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import FirebaseError = firebase.FirebaseError;
import { handleLogout } from '../../Home/ProgramsPageWrapper';
import { logButtonClick, logInput, logPageView } from '../../../common/analytics';

const pageName = 'onboarding_intro';

const gradesList = [
  'High School - Senior',
  'High School - Sophomore',
  'High School - Junior',
  'High School - Freshman',
  'College - Freshman',
  'College - Sophomore',
  'College - Junior',
  'College - Senior',
  'Parent',
  'Other'
];

function Introduction() {
  const stateRef = useRef(null);
  const gradeRef = useRef(null);

  const [id, setId] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [hasOnboarded, setHasOnboarded] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState([] as Option[]);
  const [schoolGrade, setSchoolGrade] = useState([] as Option[]);
  const [highSchool, setHighSchool] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    logPageView(pageName);
    getUser().then((user) => {
      const userInfo = user.userInfo;
      if (userInfo) {
        setId(user.id);
        setUserInfo(user.userInfo);
        setHasOnboarded(user.userInfo?.hasOnboarded || false);
        setFirstName(userInfo.firstName || '');
        setLastName(userInfo.lastName || '');
        setEmail(userInfo.email);
        setState(userInfo.state ? [userInfo.state] : []);
        setSchoolGrade(userInfo.schoolGrade ? [userInfo.schoolGrade] : []);
        setHighSchool(userInfo.highSchool || '');
      }
    });
  }, []);

  const [emailError, setEmailError] = useState('');

  const onSubmit = async () => {
    logInput('first_name', pageName, firstName);
    logInput('last_name', pageName, lastName);
    logInput('email', pageName, email);
    logInput('state', pageName, state[0].toString());
    logInput('class', pageName, schoolGrade[0].toString());
    logInput('school_name', pageName, highSchool);
    logButtonClick(hasOnboarded ? 'update' : 'next', pageName);

    const emailErr = validateEmail(email);
    setEmailError(emailErr);

    if (emailErr) {
      return;
    }

    if (auth.currentUser && auth.currentUser?.email !== email) {
      try {
        await updateEmail(auth.currentUser, email);
      } catch (e) {
        if ((e as FirebaseError).code === 'auth/requires-recent-login') {
          handleLogout(navigate);
        }
        return;
      }
    }

    await updateDoc(doc(db, dbUser, id), {
      userInfo: {
        ...userInfo,
        firstName,
        lastName,
        email,
        state: state[0] || '',
        schoolGrade: schoolGrade[0] || '',
        highSchool
      }
    });

    navigate(hasOnboarded ? RoutesEnum.Home : `${RoutesEnum.UserInfo}${UserInfoRoutes.TestScores}`);
  };

  const onStateDropdownBlur = (e: string) => {
    if (!statesList.includes(e)) {
      (stateRef as MutableRefObject<any>).current?.clear();
    } else if (!state.length) {
      setState([e]);
    }
  };

  const onGradeDropdownBlur = (e: string) => {
    if (!gradesList.includes(e)) {
      (gradeRef as MutableRefObject<any>).current?.clear();
    } else if (!state.length) {
      setSchoolGrade([e]);
    }
  };

  return (
    <PageWrapper>
      <Form className="introduction d-flex flex-column">
        <h1>Let&apos;s get you on your Path!</h1>
        <Form.Group>
          <Form.Label>First name</Form.Label>
          <Form.Control
            name="firstName"
            type="text"
            placeholder="First name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Last name</Form.Label>
          <Form.Control
            name="lastName"
            type="text"
            placeholder="Last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="email">
          <Form.Label htmlFor="email-address">Email address</Form.Label>
          <Form.Control
            id="email-address"
            name="email"
            type="email"
            className={emailError ? 'invalid' : ''}
            required
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <div className="error">{emailError}</div>}
        </Form.Group>
        <Form.Group>
          <Form.Label>Where are you from?</Form.Label>
          <Typeahead
            ref={stateRef}
            id="stateSelect"
            onChange={setState}
            options={statesList}
            placeholder="State"
            selected={state}
            onBlur={(e) => onStateDropdownBlur(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>I am a...</Form.Label>
          <Typeahead
            ref={gradeRef}
            id="gradeSelect"
            onChange={setSchoolGrade}
            options={gradesList}
            placeholder="I am a"
            selected={schoolGrade}
            onBlur={(e) => onGradeDropdownBlur(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>My school’s name...</Form.Label>
          <Form.Control
            name="school"
            type="text"
            placeholder="My school’s name"
            value={highSchool}
            onChange={(e) => setHighSchool(e.target.value)}
          />
        </Form.Group>
      </Form>
      <>
        {hasOnboarded &&
          <Button
            className="back"
            variant="link"
            id="backButton"
            onClick={() => {
              logButtonClick('home', pageName);
              navigate(RoutesEnum.Home);
            }}>
            Home
          </Button>
        }
        {!hasOnboarded &&
          <Button
            className="skip"
            variant="link"
            onClick={() => {
              logButtonClick('skip', pageName);
              navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.TestScores}`);
            }}>
            Skip
          </Button>
        }
        <Button className="submit" variant="link" onClick={() => onSubmit()}>
          {hasOnboarded ? 'Update' : 'Next'}
        </Button>
      </>
    </PageWrapper>
  );
}

export default Introduction;
