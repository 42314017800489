import PageWrapper from '../PageWrapper';
import { Button, Form } from 'react-bootstrap';
import { RoutesEnum } from '../../routes';
import { getUser, UserInfoRoutes } from '../UserInfo';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CostAndGeography.scss';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import {
  regionsList,
  regionsToStates,
  regionsToSubregions,
  statesList,
  subRegionsList,
  subregionsToStates
} from './sortedStatesList';
import { doc, updateDoc } from 'firebase/firestore';
import { db, dbUser } from '../../../firebase';
import { UserInfo } from '../../../types';
import { fetchProgramSelections } from '../../../common/buildProgram';
import { mapUserInfo } from '../../../common/mapUser';
import { logButtonClick, logInput, logPageView } from '../../../common/analytics';

const pageName = 'onboarding_geo_and_cost';

function CostAndGeography() {
  const navigate = useNavigate();

  const [sortedStatesList, setSortedStatesList] = useState(statesList);
  const [id, setId] = useState('');
  const [userInfo, setUserInfo] = useState({} as UserInfo);
  const [regions, setRegions] = useState([] as Option[]);
  const [subRegions, setSubRegions] = useState([] as Option[]);
  const [states, setStates] = useState([] as Option[]);
  const [costSensitivity, setCostSensitivity] = useState(5);
  const [hasOnboarded, setHasOnboarded] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    logPageView(pageName);
    getUser().then((user) => {
      setId(user.id);
      setUserInfo(user.userInfo);
      setRegions(user.userInfo.geographicPreferences?.regions || []);
      setSubRegions(user.userInfo.geographicPreferences?.subRegions || []);
      setStates(user.userInfo.geographicPreferences?.states || []);
      setCostSensitivity(user.userInfo.costSensitivity || 5);
      setHasOnboarded(user.userInfo.hasOnboarded);

      const homeState = user.userInfo.state;
      if (homeState && sortedStatesList.includes(homeState)) {
        let newList = sortedStatesList.filter((s) => s !== homeState);
        newList = [homeState].concat(newList);
        setSortedStatesList(newList);
      }
    });
  }, []);

  const finishHandler = async () => {
    logInput('regions', pageName, regions.toString());
    logInput('subRegions', pageName, subRegions.toString());
    logInput('states', pageName, states.toString());
    logInput('costSensitivity', pageName, costSensitivity.toString());
    logButtonClick('finish', pageName);

    const newUserInfo = {
      ...userInfo,
      geographicPreferences: {
        regions,
        subRegions,
        states
      },
      costSensitivity,
      hasOnboarded: true
    } as UserInfo;

    const topPrograms = await fetchProgramSelections(newUserInfo);

    await updateDoc(doc(db, dbUser, id),  {
      topPrograms,
      userInfo: newUserInfo
    });

    navigate(`${RoutesEnum.Home}`);
  };

  const handleRegionsChange = (selectedRegions: Option[]) => {
    setRegions(selectedRegions);

    // Compute corresponding subregions and states
    const selectedSubregions = Array.from(
      new Set(selectedRegions.flatMap(region => regionsToSubregions[String(region)]))
    );
    setSubRegions(selectedSubregions.map(subRegion => (subRegion as Option)));

    const selectedStates = Array.from(
      new Set(selectedRegions.flatMap(region => regionsToStates[String(region)]))
    );
    setStates(selectedStates.map(state => (state as Option)));

    setSelectAll(false);
  };

  const handleSubRegionsChange = (selectedSubRegions: Option[]) => {
    setSubRegions(selectedSubRegions);

    // Compute corresponding states
    const selectedStates = Array.from(new Set(selectedSubRegions.flatMap(subRegion => subregionsToStates[String(subRegion)])));
    setStates(selectedStates.map(state => (state as Option)));

    setSelectAll(false);
  };

  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    
    if (checked) {
      // If select all is checked, select all regions, subregions, and states
      setRegions(regionsList);
      setSubRegions(subRegionsList);
      setStates(sortedStatesList);
    } else {
      // If select all is unchecked, clear all selected regions, subregions, and states
      setRegions([]);
      setSubRegions([]);
      setStates([]);
    }
  };

  return (
    <PageWrapper >
      <Form className="cost-and-geography d-flex flex-column">
        <h2>
          Where do you want to go to school? Below, you can select regions, subregions, or states in the US and we&apos;ll find the best programs in your selected regions.
        </h2>
        <Form.Group>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAllChange}
              style={{ marginRight: '5px' }}
            />
            <label style={{ margin: '5px' }}>All / I&apos;m not sure</label>
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label>Regions</Form.Label>
          <Typeahead
            id="regionsSelect"
            onChange={handleRegionsChange}
            options={regionsList}
            placeholder="Regions"
            multiple
            selected={regions}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Sub Regions</Form.Label>
          <Typeahead
            id="subRegionsSelect"
            onChange={handleSubRegionsChange}
            options={subRegionsList}
            placeholder="Sub Regions"
            multiple
            selected={subRegions}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>States</Form.Label>
          <Typeahead
            id="statesSelect"
            onChange={setStates}
            options={sortedStatesList}
            placeholder="States"
            multiple
            selected={states}
          />
        </Form.Group>
        <div className="cost-wrapper">
          <h2>
            How concerned are you with the up-front cost of college? Finish the following sentence and remember you can always change this later!
          </h2>
          <h2>
            I am...
          </h2>
          <Form.Group className="cost d-flex align-items-center">
            <span className="less">not concerned</span>
            <Form.Range
              onChange={(e) => setCostSensitivity(+e.target.value)}
              min={0}
              max={10}
              step={1}
              value={costSensitivity}></Form.Range>
            <span className="most">very concerned</span>
          </Form.Group>
        </div>
      </Form>
      <>
        <Button
          className="back"
          variant="link"
          id="backButton"
          onClick={() => {
            logButtonClick('back', pageName);
            navigate(hasOnboarded ? RoutesEnum.Home : `${RoutesEnum.UserInfo}${UserInfoRoutes.FavoriteClasses}`);
          }}>
          {hasOnboarded ? 'Home' : 'Back'}
        </Button>
        <Button className="submit" variant="link" id="finishButton" onClick={finishHandler}>
          {hasOnboarded ? 'Update' : 'Finish'}
        </Button>
      </>
    </PageWrapper>
  );
}

export default CostAndGeography;
