import { Navigate, NavLink, Route, Routes, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Explore from './Explore/Explore';
import Compare from './Compare/Compare';
import Announcements from './Announcements/Announcements';
import './Base.scss';
import home from '../assets/home.svg';
import homeFilled from '../assets/home-filled.svg';
import fav from '../assets/fav-nav.svg';
import favFilled from '../assets/fav-filled-nav.svg';
import explore from '../assets/explore.svg';
import exploreFilled from '../assets/explore-filled.svg';
import compare from '../assets/compare.svg';
import compareFilled from '../assets/compare-filled.svg';
import announcements from '../assets/announcements.svg';
import announcementsFilled from '../assets/announcements-filled.svg';
import ProgramMatches from './Home/ProgramMatches';
import Home from './Home/Home';
import SavedPrograms from './Home/SavedPrograms';
import { RoutesEnum } from './routes';
import PathScoreInfo from './Information/PathScore/PathScore';
import ProgramScoreInfo from './Information/ProgramScore/ProgramScore';
import { getUser } from './UserInfo/UserInfo';
import { User } from '../types';
import ProgramDetails from './ProgramDetails/ProgramDetails';
import { logButtonClick, pageNameByUrl } from '../common/analytics';

export const HomeRoutesEnum = {
  ProgramMatches: `${RoutesEnum.Home}/program-matches`,
  SavedPrograms: `${RoutesEnum.Home}/saved-programs`
};

function Base() {
  const [user, setUser] = useState({} as User);

  const location = useLocation();
  const pageName = pageNameByUrl(location.pathname);

  useEffect(() => {
    getUser().then((res) => {
      setUser(res);
    });
  }, []);

  return (
    <span className="base">
      <div className="page">
        <Routes>
          <Route path="/" element={<Navigate to={RoutesEnum.Home} />} />
          <Route path={RoutesEnum.Home} element={<Home user={user} />} />
          <Route path={HomeRoutesEnum.ProgramMatches} element={<ProgramMatches user={user} />} />
          <Route path={HomeRoutesEnum.SavedPrograms} element={<SavedPrograms user={user} />} />
          <Route path={RoutesEnum.Explore} element={<Explore user={user} />} />
          <Route path={RoutesEnum.Compare} element={<Compare user={user} />} />
          <Route path={RoutesEnum.Announcements} element={<Announcements user={user} />} />
          <Route path={RoutesEnum.PathScoreInfo} element={<PathScoreInfo />} />
          <Route path={RoutesEnum.ProgramScoreInfo} element={<ProgramScoreInfo />} />
          <Route path={RoutesEnum.ProgramDetails} element={<ProgramDetails />} />
        </Routes>
      </div>
      <footer className="d-flex justify-content-between align-items-center">
        <NavLink to={RoutesEnum.Home} onClick={() => logButtonClick('home', pageName)}>
          {location.pathname === RoutesEnum.Home || (location.pathname.includes(RoutesEnum.Home + '/') && !!location.pathname.includes(HomeRoutesEnum.SavedPrograms + '/')) ? (
            <img alt="Home selected" src={homeFilled} />
          ) : (
            <img alt="Home" src={home} />
          )}
        </NavLink>
        <NavLink to={HomeRoutesEnum.SavedPrograms} onClick={() => logButtonClick('saved_programs', pageName)}>
          {location.pathname === HomeRoutesEnum.SavedPrograms || location.pathname.includes(HomeRoutesEnum.SavedPrograms + '/') ? (
            <img alt="Star selected" src={favFilled} />
          ) : (
            <img alt="Star" src={fav} />
          )}
        </NavLink>
        <NavLink to={RoutesEnum.Explore} onClick={() => logButtonClick('explore', pageName)}>
          {location.pathname === RoutesEnum.Explore ? (
            <img alt="Explore selected" src={exploreFilled} />
          ) : (
            <img alt="Explore" src={explore} />
          )}
        </NavLink>
        <NavLink to={RoutesEnum.Compare} onClick={() => logButtonClick('compare', pageName)}>
          {location.pathname === RoutesEnum.Compare ? (
            <img alt="Compare selected" src={compareFilled} />
          ) : (
            <img alt="Compare" src={compare} />
          )}
        </NavLink>
      </footer>
    </span>
  );
}

export default Base;
