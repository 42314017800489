export const regionsList = ['Northeast', 'Midwest', 'West', 'South'];
export const subRegionsList = [
  'New England',
  'Mid-Atlantic',
  'Great Lakes',
  'Plains',
  'Mountain',
  'Far West',
  'Other',
  'Southeast',
  'Southwest'
];

export let statesList = [
  'California',
  'Florida',
  'Pennsylvania',
  'New York',
  'Ohio',
  'Texas',
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'North Carolina',
  'North Dakota',
  'Oklahoma',
  'Oregon',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];

// Map regions to subregions
export const regionsToSubregions: { [region: string]: string[] } = {
  'Northeast': ['New England', 'Mid-Atlantic'],
  'Midwest': ['Great Lakes', 'Plains'],
  'West': ['Mountain', 'Far West', 'Other'],
  'South': ['Southeast', 'Southwest']
};

// Map regions to states
export const regionsToStates: { [region: string]: string[] } = {
  'Northeast': ['Connecticut', 'Maine', 'Massachusetts', 'New Hampshire', 'New Jersey', 'New York', 'Pennsylvania', 'Rhode Island', 'Vermont'],
  'Midwest': ['Illinois', 'Indiana', 'Iowa', 'Kansas', 'Michigan', 'Minnesota', 'Missouri', 'Nebraska', 'North Dakota', 'Ohio', 'South Dakota', 'Wisconsin'],
  'West': ['Alaska', 'Arizona', 'California', 'Colorado', 'Hawaii', 'Idaho', 'Montana', 'Nevada', 'New Mexico', 'Oregon', 'Utah', 'Washington', 'Wyoming'],
  'South': ['Alabama', 'Arkansas', 'Delaware', 'Florida', 'Georgia', 'Kentucky', 'Louisiana', 'Maryland', 'Mississippi', 'North Carolina', 'Oklahoma', 'South Carolina', 'Tennessee', 'Texas', 'Virginia', 'West Virginia']
};

// Map subregions to states
export const subregionsToStates: { [subregion: string]: string[] } = {
  'New England': ['Connecticut', 'Maine', 'Massachusetts', 'New Hampshire', 'Rhode Island', 'Vermont'],
  'Mid-Atlantic': ['New Jersey', 'New York', 'Pennsylvania'],
  'Great Lakes': ['Illinois', 'Indiana', 'Michigan', 'Minnesota', 'Ohio', 'Wisconsin'],
  'Plains': ['Iowa', 'Kansas', 'Missouri', 'Nebraska', 'North Dakota', 'South Dakota'],
  'Mountain': ['Arizona', 'Colorado', 'Idaho', 'Montana', 'Nevada', 'New Mexico', 'Utah', 'Wyoming'],
  'Far West': ['Alaska', 'California', 'Hawaii', 'Oregon', 'Washington'],
  'Other': ['Guam', 'Northern Mariana Islands', 'Puerto Rico', 'Virgin Islands'],
  'Southeast': ['Alabama', 'Arkansas', 'Florida', 'Georgia', 'Kentucky', 'Louisiana', 'Mississippi', 'North Carolina', 'South Carolina', 'Tennessee', 'Virginia', 'West Virginia'],
  'Southwest': ['Oklahoma', 'Texas']
};
