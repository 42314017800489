import PageWrapper from '../PageWrapper';
import { Button, Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { RoutesEnum } from '../../routes';
import { getUser, UserInfoRoutes } from '../UserInfo';
import { useNavigate } from 'react-router-dom';
import './FavoriteClasses.scss';
import { FavoriteClassModel } from '../../../types';
import okButton from '../../../assets/ok-button.svg';
import addButton from '../../../assets/add-button.svg';
import deleteButton from '../../../assets/delete.svg';
import ClassChip, { ChipSize } from './ClassChip';
import { db, dbUser } from '../../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { logButtonClick, logInput, logPageView } from '../../../common/analytics';
import { fetchProgramSelections } from '../../../common/buildProgram';
import { UserInfo } from '../../../types';

const pageName = 'onboarding_classes';

export const Classes = {
  Science: {
    Biology: 'Biology',
    Chemistry: 'Chemistry',
    Physics: 'Physics',
    Computer: 'Computer'
  },
  Math: {
    Algebra: 'Algebra',
    Calculus: 'Calculus',
    Geometry: 'Geometry',
    Statistics: 'Statistics'
  },
  Social: {
    Geography: 'Geography',
    Economics: 'Economics',
    History: 'History',
    Humanities: 'Humanities'
  },
  English: {
    Languages: 'Languages',
    Written: 'Written',
    Literature: 'Literature'
  },
  Arts: {
    Visual: 'Visual',
    Performing: 'Performing',
    Music: 'Music'
  },
  Health: {
    PhysEd: 'Phys.Ed.',
    Health: 'Health'
  }
};

const pointsCount = 10;

function FavoriteClasses() {
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [classes, setClasses] = useState([] as FavoriteClassModel[]);
  const [hasOnboarded, setHasOnboarded] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedClasses, setSelectedClasses] = useState([] as string[]);
  const [isInvalidRows, setIsInvalidRows] = useState([] as boolean[]);
  const [sum, setSum] = useState(0);

  useEffect(() => {
    logPageView(pageName);
    getUser().then((user) => {
      setId(user.id);
      setUserInfo(user.userInfo);
      setHasOnboarded(user.userInfo?.hasOnboarded || false);

      const newClasses = user.userInfo.classes || [];
      setClasses(newClasses);
      setIsInvalidRows(newClasses.map((cl) => !isValueValid(cl.value)));
      checkSum(newClasses);
    });
  }, []);

  const clickHandler = (className: string, selected: boolean) => {
    if (selected) {
      setSelectedClasses((current) => [...current, className]);
    } else {
      setSelectedClasses(selectedClasses.filter((selectedClass) => selectedClass !== className));
    }
  };

  const addHandler = () => {
    logButtonClick('done', pageName, '', 'select');
    let newClasses = selectedClasses.map((className) => {
      logInput('select', pageName, className, '', 'select');
      const value = classes.find((cl) => cl.class === className)?.value;
      return { class: className, value: value || 0 } as FavoriteClassModel;
    });

    setIsInvalidRows(newClasses.map((cl) => !isValueValid(cl.value)));

    setClasses(newClasses);
    setIsSelecting(false);
  };

  const openSelectHandler = () => {
    logButtonClick('select', pageName);
    setSelectedClasses(classes.map((cl) => cl.class));
    setIsSelecting(true);
  };

  const isSelected = (className: string) => {
    return !!classes.find((cl) => cl.class === className);
  };

  const setValue = (value: string, index: number) => {
    const newValue = value ? +value : null;
    const newClasses = classes.map((cl, i) => {
      if (i !== index) {
        return cl;
      } else {
        cl.value = newValue;
        return cl;
      }
    });
    setClasses(newClasses);
    checkSum(newClasses);

    isInvalidRows[index] = !isValueValid(newValue);
    setIsInvalidRows(isInvalidRows);
  };

  const checkSum = (checkClasses: FavoriteClassModel[]) => {
    let sum = 0;
    checkClasses.forEach((cl) => (sum += cl.value || 0));
    setSum(sum);
  };

  const isValueValid = (value: number | null): boolean => {
    return !!value && value > 0;
  };

  const deleteClass = (index: number) => {
    const newClasses = classes.filter((cl, i) => i !== index);
    setClasses(newClasses);
    checkSum(newClasses);
  };

  const nextHandler = async () => {
    classes.forEach((cl) => {
      logInput('hours', pageName, cl?.value?.toString() || '', cl.class);
    });
    logButtonClick('next', pageName);

    const newUserInfo = {
      ...userInfo,
      classes
    } as UserInfo;

    let topPrograms;
    if (hasOnboarded) {
      topPrograms = await fetchProgramSelections(newUserInfo);
    }

    await updateDoc(doc(db, dbUser, id),  {
      ...(!hasOnboarded ? {} : { topPrograms }),
      userInfo: newUserInfo
    });

    navigate(hasOnboarded ? RoutesEnum.Home : `${RoutesEnum.UserInfo}${UserInfoRoutes.CostAndGeography}`);
  };

  return (
    <PageWrapper>
      {isSelecting ? (
        <div className="class-select">
          <h1>Click on a class to select it</h1>
          <div className="categories d-flex justify-content-center">
            <div className="category d-flex flex-column align-items-center">
              <h2 className="science">Science</h2>
              <ClassChip
                classText={Classes.Science.Biology}
                selected={isSelected(Classes.Science.Biology)}
                onClick={(selected) => clickHandler(Classes.Science.Biology, selected)}
              />
              <ClassChip
                classText={Classes.Science.Chemistry}
                selected={isSelected(Classes.Science.Chemistry)}
                onClick={(selected) => clickHandler(Classes.Science.Chemistry, selected)}
              />
              <ClassChip
                classText={Classes.Science.Physics}
                selected={isSelected(Classes.Science.Physics)}
                onClick={(selected) => clickHandler(Classes.Science.Physics, selected)}
              />
              <ClassChip
                classText={Classes.Science.Computer}
                selected={isSelected(Classes.Science.Computer)}
                onClick={(selected) => clickHandler(Classes.Science.Computer, selected)}
              />
            </div>
            <div className="category d-flex flex-column align-items-center">
              <h2 className="math">Math</h2>
              <ClassChip
                classText={Classes.Math.Algebra}
                selected={isSelected(Classes.Math.Algebra)}
                onClick={(selected) => clickHandler(Classes.Math.Algebra, selected)}
              />
              <ClassChip
                classText={Classes.Math.Calculus}
                selected={isSelected(Classes.Math.Calculus)}
                onClick={(selected) => clickHandler(Classes.Math.Calculus, selected)}
              />
              <ClassChip
                classText={Classes.Math.Geometry}
                selected={isSelected(Classes.Math.Geometry)}
                onClick={(selected) => clickHandler(Classes.Math.Geometry, selected)}
              />
              <ClassChip
                classText={Classes.Math.Statistics}
                selected={isSelected(Classes.Math.Statistics)}
                onClick={(selected) => clickHandler(Classes.Math.Statistics, selected)}
              />
            </div>
            <div className="category d-flex flex-column align-items-center">
              <h2 className="social">Social</h2>
              <ClassChip
                classText={Classes.Social.Geography}
                selected={isSelected(Classes.Social.Geography)}
                onClick={(selected) => clickHandler(Classes.Social.Geography, selected)}
              />
              <ClassChip
                classText={Classes.Social.Economics}
                selected={isSelected(Classes.Social.Economics)}
                onClick={(selected) => clickHandler(Classes.Social.Economics, selected)}
              />
              <ClassChip
                classText={Classes.Social.History}
                selected={isSelected(Classes.Social.History)}
                onClick={(selected) => clickHandler(Classes.Social.History, selected)}
              />
              <ClassChip
                classText={Classes.Social.Humanities}
                selected={isSelected(Classes.Social.Humanities)}
                onClick={(selected) => clickHandler(Classes.Social.Humanities, selected)}
              />
            </div>
            <div className="category d-flex flex-column align-items-center">
              <h2 className="english">English</h2>
              <ClassChip
                classText={Classes.English.Languages}
                selected={isSelected(Classes.English.Languages)}
                onClick={(selected) => clickHandler(Classes.English.Languages, selected)}
              />
              <ClassChip
                classText={Classes.English.Written}
                selected={isSelected(Classes.English.Written)}
                onClick={(selected) => clickHandler(Classes.English.Written, selected)}
              />
              <ClassChip
                classText={Classes.English.Literature}
                selected={isSelected(Classes.English.Literature)}
                onClick={(selected) => clickHandler(Classes.English.Literature, selected)}
              />
            </div>
            <div className="category d-flex flex-column align-items-center">
              <h2 className="arts">Arts</h2>
              <ClassChip
                classText={Classes.Arts.Visual}
                selected={isSelected(Classes.Arts.Visual)}
                onClick={(selected) => clickHandler(Classes.Arts.Visual, selected)}
              />
              <ClassChip
                classText={Classes.Arts.Performing}
                selected={isSelected(Classes.Arts.Performing)}
                onClick={(selected) => clickHandler(Classes.Arts.Performing, selected)}
              />
              <ClassChip
                classText={Classes.Arts.Music}
                selected={isSelected(Classes.Arts.Music)}
                onClick={(selected) => clickHandler(Classes.Arts.Music, selected)}
              />
            </div>
            <div className="category d-flex flex-column align-items-center">
              <h2 className="health">Health</h2>
              <ClassChip
                classText={Classes.Health.PhysEd}
                selected={isSelected(Classes.Health.PhysEd)}
                onClick={(selected) => clickHandler(Classes.Health.PhysEd, selected)}
              />
              <ClassChip
                classText={Classes.Health.Health}
                selected={isSelected(Classes.Health.Health)}
                onClick={(selected) => clickHandler(Classes.Health.Health, selected)}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="favorite-classes d-flex flex-column justify-content-between">
          <div>
            <label className="description">Almost done! Tell us more about the classes or topics you love.</label>
            <label className="description">
              We’ve given you {pointsCount} points to spend across the classes that you like the most. The more points
              you spend on a class, the more you like it!
            </label>
            <label className="description">You can always come back later to update the classes and points.</label>
            <h1>
              You’ve got <span>{pointsCount - sum}</span> points to spend!
            </h1>
            <div className="d-flex flex-column align-items-center">
              <div className="selected-classes d-flex flex-column align-items-center">
                {classes?.length == 0 && 
                  <p className="description">
                    First, click on the green button to select your favorite subjects and then spread your points among them.
                  </p>
                }
                {classes?.length > 0 && 
                  <div className="headers d-flex justify-content-between">
                    <span>Class/Topic</span>
                    <span>Points</span>
                  </div>
                }
                {classes?.length > 0 &&
                  classes.map((selectedClass, index) => (
                    <div key={selectedClass.class} className="class-row d-flex justify-content-between w-100">
                      <ClassChip classText={selectedClass.class} selected={true} chipSize={ChipSize.Big}></ClassChip>
                      <Form.Control
                        type="number"
                        required
                        value={selectedClass.value || selectedClass.value === 0 ? selectedClass.value : ''}
                        min="0"
                        className={isInvalidRows[index] || sum > pointsCount ? 'invalid' : ''}
                        onChange={(e) => setValue(e.target.value, index)}
                      />
                      <img
                        className="delete-button"
                        alt="Delete"
                        src={deleteButton}
                        onClick={() => {
                          logButtonClick('remove', pageName, selectedClass.class);
                          deleteClass(index);
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center flex-column">
            <img className="add-button" alt="Add" src={addButton} onClick={() => openSelectHandler()} />
            <div className="space-div"></div>
          </div>
        </div>
      )}
      {isSelecting ? (
        <>
          <Button
            className="back"
            variant="link"
            id="backButton"
            onClick={() => {
              logButtonClick('back', pageName, '', 'select');
              setIsSelecting(false);
            }}>
            Back
          </Button>
          <img alt="Ok" src={okButton} onClick={() => addHandler()} />
        </>
      ) : (
        <>
          <Button
            className="back"
            variant="link"
            id="backButton"
            onClick={() => {
              logButtonClick('back', pageName);
              navigate(hasOnboarded ? RoutesEnum.Home : `${RoutesEnum.UserInfo}${UserInfoRoutes.Activities}`);
            }}>
            {hasOnboarded ? 'Home' : 'Back'}
          </Button>
          {!hasOnboarded && 
            <Button
              className="skip"
              variant="link"
              id="skipButton"
              onClick={() => {
                logButtonClick('skip', pageName);
                navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.CostAndGeography}`);
              }}>
              Skip
            </Button>
          }
          <Button
            className="submit"
            disabled={sum !== pointsCount || isInvalidRows.find((row) => row)}
            variant="link"
            id="nextButton"
            onClick={() => nextHandler()}>
            {hasOnboarded ? 'Update' : 'Next'}
          </Button>
        </>
      )}
    </PageWrapper>
  );
}

export default FavoriteClasses;
