import React, { useEffect, useState } from 'react';
import logo from '../../assets/logo.svg';
import {
  browserSessionPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { auth, sessionIdKey } from '../../firebase';
import { NavLink, useNavigate } from 'react-router-dom';
import { browserLocalPersistence } from '@firebase/auth';
import Loading from '../../components/Loading';
import './Auth.scss';
import { Button, Form, InputGroup } from 'react-bootstrap';
import AuthHeader from './AuthHeader';
import { getFirebaseErrorText, validateEmail, validatePassword } from './Validation';
import firebase from 'firebase/compat/app';
import { logButtonClick, logInput, logPageView } from '../../common/analytics';
import { v4 } from 'uuid';
import FirebaseError = firebase.FirebaseError;

const pageName = 'login';

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [rememberMe, setRememberMe] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    logPageView(pageName);
  }, []);

  if (!auth.currentUser) {
    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        setLoading(false);
        if (user) {
          navigate('/');
        }
      });
    });
  } else {
    setLoading(false);
  }

  const onLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    logInput('username', pageName, email);
    logInput('password', pageName, '');
    logButtonClick('login', pageName);

    const emailErr = validateEmail(email);
    setEmailError(emailErr);
    const passwordErr = validatePassword(password);
    setPasswordError(passwordErr);

    if (emailErr || passwordErr) {
      return;
    }

    setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence)
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password);
      })
      .then(() => {
        localStorage.setItem(sessionIdKey, v4());
      })
      .catch((error) => {
        const text = getFirebaseErrorText(error as FirebaseError);
        error.code.includes('password') ? setPasswordError(text) : setEmailError(text);
        throw error;
      });
  };

  return loading ? (
    <Loading />
  ) : (
    <div className="auth d-flex flex-column justify-content-between h-100">
      <AuthHeader />
      <Form className="d-flex flex-column">
        <Form.Group className="email">
          <Form.Label htmlFor="email-address">Email address</Form.Label>
          <Form.Control
            id="email-address"
            name="email"
            type="email"
            className={emailError ? 'invalid' : ''}
            required
            placeholder="Email address"
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <div className="error">{emailError}</div>}
        </Form.Group>

        <Form.Group className="password">
          <Form.Label htmlFor="password">Password</Form.Label>
          <InputGroup>
            <Form.Control
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              required
              placeholder="Password"
              className={passwordError ? 'invalid' : ''}
              onChange={(e) => setPassword(e.target.value)}></Form.Control>
            <InputGroup.Text className={passwordError ? 'invalid' : ''}>
              <i
                onClick={() => setShowPassword(!showPassword)}
                className={`bi ${showPassword ? 'bi-eye' : 'bi-eye-slash'}`}></i>
            </InputGroup.Text>
          </InputGroup>
          {passwordError && <div className="error">{passwordError}</div>}
        </Form.Group>

        <div className="d-flex justify-content-between align-items-center">
          <Form.Group className="round">
            <input
              type="checkbox"
              checked={rememberMe}
              id="checkbox"
              onChange={() => {
                logButtonClick('remember_me', pageName);
                setRememberMe(!rememberMe);
              }}
            />
            <label htmlFor="checkbox"></label>
            <span className="remember-me-label" onClick={() => setRememberMe(!rememberMe)}>
              Remember me
            </span>
          </Form.Group>

          <NavLink
            to="/reset-password"
            className="forgot-password"
            onClick={() => logButtonClick('forgot_password', pageName)}>
            Forgot password
          </NavLink>
        </div>

        <Button className="main-button" type="submit" onClick={onLogin}>
          LOGIN
        </Button>
        <label className="sign-in-label text-center">
          Don’t have an account?<br />
          <NavLink to="/signup" onClick={() => logButtonClick('signup', pageName)}>
            Sign up
          </NavLink>
        </label>
      </Form>
      <div className="footer d-flex flex-column justify-content-between align-self-center">
        <div>
          <h2>Find your {<img alt="Logo" src={logo} width="110px" />}</h2>
          <label>Explore colleges and majors.</label>
          <label>Understand career outcomes.</label>
          <label>Find your college program match.</label>
        </div>
        <div className="links d-flex justify-content-center">
          <a rel="noreferrer" target="_blank" href="https://www.path-technologies.com/terms-and-condition">
            Terms of Service
          </a>
          <a rel="noreferrer" target="_blank" href="https://www.path-technologies.com/privacy">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;
