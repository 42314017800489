import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { User } from '../types';
import { auth, db, dbUser } from '../firebase';
import { collection, doc, getDocs, limit, query, updateDoc, where } from 'firebase/firestore';
import { RoutesEnum } from '../pages/routes';
import { UserInfoRoutes } from '../pages/UserInfo/UserInfo';
import { useNavigate } from 'react-router-dom';
import { logButtonClick, logInput } from '../common/analytics';
import { testScoresPageName } from '../pages/UserInfo/TestScores/TestScores';
import { fetchProgramSelections } from '../common/buildProgram';
import { UserInfo } from '../types';

export function useUserInfoScoresHook() {
  const [id, setId] = useState('');
  const [userInfo, setUserInfo] = useState({} as User['userInfo']);
  const [hasOnboarded, setHasOnboarded] = useState(false);
  const [isSatSelected, setIsSatSelected] = useState(true);
  const [isActSelected, setIsActSelected] = useState(false);
  const [score, setScore] = useState<number | string>('');
  const [testScoreInputError, setTestScoreInputError] = useState('');
  const [unweightGpaInputError, setUnweightGpaInputError] = useState('');
  const [weightGpaInputError, setWeightGpaInputError] = useState('');
  const [satScore, setSatScore] = useState<number | string>('');
  const [actScore, setActScore] = useState<number | string>('');
  const [unweightedGPA, setUnweightedGPA] = useState<number | string>('');
  const [weightedGPA, setWeightedGPA] = useState<number | string>('');
  const navigate = useNavigate();

  async function handleClickNextButton() {
    logInput('score', testScoresPageName, score.toString());
    logInput('unweighted_gpa', testScoresPageName, unweightedGPA.toString());
    logInput('weighted_gpa', testScoresPageName, weightedGPA.toString());
    logInput('score', testScoresPageName, score.toString());
    logButtonClick('next', testScoresPageName);

    const newUserInfo = {
      ...userInfo,
      scores: {
        actScore: actScore,
        satScore: satScore,
        unweightedGPA: unweightedGPA,
        weightedGPA: weightedGPA
      }
    } as UserInfo;


    let topPrograms;
    if (hasOnboarded) {
      topPrograms = await fetchProgramSelections(newUserInfo);
    }

    await updateDoc(doc(db, dbUser, id),  {
      ...(!hasOnboarded ? {} : { topPrograms }),
      userInfo: newUserInfo
    });

    nextPage();
  }

  function nextPage() {
    navigate(hasOnboarded ? RoutesEnum.Home : `${RoutesEnum.UserInfo}${UserInfoRoutes.Activities}`);
  }

  function prevPage() {
    navigate(hasOnboarded ? RoutesEnum.Home : `${RoutesEnum.UserInfo}${UserInfoRoutes.Introduction}`);
  }

  function handleClickSatButton() {
    logButtonClick('sat', testScoresPageName);
    if (!isSatSelected) {
      setIsSatSelected(!isSatSelected);
      setIsActSelected(!isActSelected);
      setScore(satScore || '');
    }
  }

  function handleClickActButton() {
    logButtonClick('act', testScoresPageName);
    if (!isActSelected) {
      setIsActSelected(!isActSelected);
      setIsSatSelected(!isSatSelected);
      setScore(actScore || '');
    }
  }

  function addScore(value: number, event: BaseSyntheticEvent) {
    setTestScoreInputError(event.target.validity.valid ? '' : event.target.validationMessage);
    value ? setScore(value) : setScore('');
    isSatSelected && setSatScore(value);
    isActSelected && setActScore(value);
  }

  function addUnweightedGPA(event: BaseSyntheticEvent) {
    setUnweightGpaInputError(event.target.validity.valid ? '' : event.target.validationMessage);
    event.target.value ? setUnweightedGPA(parseFloat(event.target.value)) : setUnweightedGPA('');
  }

  function addWeightedGPA(event: BaseSyntheticEvent) {
    setWeightGpaInputError(event.target.validity.valid ? '' : event.target.validationMessage);
    event.target.value ? setWeightedGPA(parseFloat(event.target.value)) : setWeightedGPA('');
  }

  async function getUser() {
    const userEmail = auth.currentUser?.email;
    const queryUser = query(collection(db, dbUser), where('userInfo.email', '==', userEmail), limit(1));
    const snapshot = await getDocs(queryUser);

    setUserInfo((prevState) => {
      return Object.assign(prevState, snapshot.docs[0].data().userInfo);
    });
    setId(snapshot.docs[0].id);
  }

  useEffect(() => {
    getUser().then(() => {
      setSatScore(userInfo?.scores?.satScore || '');
      setActScore(userInfo?.scores?.actScore || '');
      setUnweightedGPA(userInfo?.scores?.unweightedGPA || '');
      setWeightedGPA(userInfo?.scores?.weightedGPA || '');
      setHasOnboarded(userInfo?.hasOnboarded || false);

      if (isSatSelected) {
        setScore(userInfo?.scores?.satScore || '');
      }
    });
  }, [userInfo, isSatSelected]);

  return {
    addScore,
    addUnweightedGPA,
    addWeightedGPA,
    handleClickActButton,
    handleClickNextButton,
    handleClickSatButton,
    hasOnboarded,
    isActSelected,
    isSatSelected,
    nextPage,
    prevPage,
    score,
    testScoreInputError,
    unweightedGPA,
    unweightGpaInputError,
    weightedGPA,
    weightGpaInputError
  };
}
