import { ReactNode } from 'react';
import './PageWrapper.scss';
import Base from '../Base';

interface WrapperProps {
  children: ReactNode[];
}

function PageWrapper({ children }: WrapperProps) {
  return (
    <>
      {children[0] && <div className="page-content">{children[0]}</div>}
      {children[1] && <footer className="d-flex justify-content-between align-items-center">{children[1]}</footer>}
      {/*{hasOnboarded && <Base />}*/}
    </>
  );
}

export default PageWrapper;
