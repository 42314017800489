export const RoutesEnum = {
  Home: '/home',
  Explore: '/explore',
  Compare: '/compare',
  Feedback: '/feedback',
  Announcements: '/announcements',
  UserInfo: '/user-info',
  PathScoreInfo: '/what-is-a-path-score',
  ProgramScoreInfo: '/what-is-a-program-score',
  ProgramDetails: '/program-details'
};
