import { db, dbFieldsOfStudy, dbSchools } from '../firebase';
import { collection, getDocs, query } from 'firebase/firestore';

export interface FieldOfStudyWithClasses {
  description: string;
  fieldOfStudy: string;
  rank1: string;
  rank2: string;
  rank3: string;
  rank4: string;
  rank5: string;
}

export async function getFieldsOfStudy(): Promise<FieldOfStudyWithClasses[]> {
  let storedFieldsOfStudy = window.sessionStorage.getItem(dbFieldsOfStudy);

  // If we have not locally stored fields of study, let's fetch from Firebase and store
  if (!storedFieldsOfStudy?.length) {
    const queryMajor = query(collection(db, dbFieldsOfStudy));
    const snapshot = await getDocs(queryMajor);
    const majors = snapshot.docs[0].data().fieldsOfStudy;

    storedFieldsOfStudy = JSON.stringify(majors);
    try {
      window.sessionStorage.setItem(dbFieldsOfStudy, storedFieldsOfStudy);
    } catch (e) {
      console.error('Error setting fields of study in sessionStorage: ', e);
    }
  }

  return JSON.parse(storedFieldsOfStudy || '');
}

export interface School {
  school: string;
  schoolDescription: string;
  state: string;
}

export async function getSchools(): Promise<School[]> {
  let storedSchools = window.sessionStorage.getItem(dbSchools);

  // If we have not locally stored schools, let's fetch from Firebase and store
  if (!storedSchools?.length) {
    const querySchools = query(collection(db, dbSchools));
    const snapshot = await getDocs(querySchools);
    let schools = [] as { school: string }[];
    snapshot.docs.forEach((doc) => {
      schools = [...schools, ...doc.data().schools];
    });

    storedSchools = JSON.stringify(schools);
    try {
      window.sessionStorage.setItem(dbSchools, storedSchools);
    } catch (e) {
      console.error('Error setting schools in sessionStorage: ', e);
    }
  }

  return JSON.parse(storedSchools || '');
}
