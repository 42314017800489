import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from '../routes';
import navigStar from '../../assets/navig-star.svg';
import { UserInfoRoutes } from '../UserInfo/UserInfo';
import introduction from '../../assets/introduction.svg';
import testScores from '../../assets/test-scores.svg';
import interestsAndActivities from '../../assets/interests-and-activities.svg';
import favoriteClasses from '../../assets/favorite-classes.svg';
import costAndGeography from '../../assets/cost-and-geography.svg';
import { HomeRoutesEnum } from '../Base';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import './ProgramsPageWrapper.scss';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { User } from '../../types';
import { logButtonClick, pageNameByUrl } from '../../common/analytics';
import { Button } from 'react-bootstrap';

export function handleLogout(navigate: NavigateFunction) {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      navigate('/login');
    })
    .catch((error) => {
      // An error happened.
      throw error;
    });
}

interface WrapperProps {
  user: User;
  children: ReactNode | ReactNode[];
}

function ProgramsPageWrapper({ user, children }: WrapperProps) {
  const navigate = useNavigate();

  const pageName = pageNameByUrl(location.pathname);

  return (
    <>
      <div className="user-section d-flex flex-column justify-content-between">
        <div className="d-flex flex-column align-items-center">
          <span className="name">
            {user.userInfo?.firstName} {user.userInfo?.lastName}
          </span>
          <label>{user.userInfo?.schoolGrade}, {user.userInfo?.state} </label>
          {/*<Button onClick={() => handleLogout(navigate)}>Logout</Button>*/}
        </div>
        <div className="navigation-panel d-flex justify-content-center">
          <div
            className="d-flex justify-content-center align-items-center navigation-icon"
            onClick={() => {
              logButtonClick('onboarding_intro', pageName);
              navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.Introduction}`);
            }}>
            <img alt="Introduction" src={introduction} />
          </div>
          <div
            className="d-flex justify-content-center align-items-center navigation-icon"
            onClick={() => {
              logButtonClick('onboarding_scores', pageName);
              navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.TestScores}`);
            }}>
            <img alt="Test Scores" src={testScores} />
          </div>
          <div
            className="d-flex justify-content-center align-items-center navigation-icon"
            onClick={() => {
              logButtonClick('onboarding_activities', pageName);
              navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.Activities}`);
            }}>
            <img alt="Interests And Activities" src={interestsAndActivities} />
          </div>
          <div
            className="d-flex justify-content-center align-items-center navigation-icon"
            onClick={() => {
              logButtonClick('onboarding_classes', pageName);
              navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.FavoriteClasses}`);
            }}>
            <img alt="Favorite Classes" src={favoriteClasses} />
          </div>
          <div
            className="d-flex justify-content-center align-items-center navigation-icon"
            onClick={() => {
              logButtonClick('onboarding_geo_and_cost', pageName);
              navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.CostAndGeography}`);
            }}>
            <img alt="CostAndGeography" src={costAndGeography} />
          </div>
        </div>
      </div>
      <div className="content">{children}</div>
    </>
  );
}

export default ProgramsPageWrapper;
