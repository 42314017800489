import './Compare.scss';
import ProgramsPageWrapper from '../Home/ProgramsPageWrapper';
import { User } from '../../types';
import logo from '../../assets/logo.svg';

function Compare({ user }: { user: User }) {
  const feedbackFormUrl = 'https://www.path-technologies.com/feedback-beta-launch-students';

  return (
    <ProgramsPageWrapper user={user}>
      <div className="wrapper">
        <img src={logo} alt="Path Logo" className="logo" />
        <h1 className="title">More Coming Soon!</h1>
        <label>In the meantime, we&apos;d love to hear your feedback:</label>
        <a href="https://www.path-technologies.com/feedback-beta-launch-students" target="_blank" rel="noopener noreferrer">
          <button className="feedback-button">Feedback Survey</button>
        </a>
      </div>
    </ProgramsPageWrapper>
  );
}

export default Compare;
