import React, { useEffect, useState } from 'react';
import './ProgramDetails.scss';
import { Accordion, Badge, Table } from 'react-bootstrap';
import { getStateCode } from '../../common/buildProgram';
import check from '../../assets/check.svg';
import favFilled from '../../assets/fav-filled.svg';
import fav from '../../assets/fav.svg';
import info from '../../assets/info.svg';
import infoDark from '../../assets/info-dark.svg';
import { User } from '../../types';
import { ProgramModel } from '../../components/Program';
import { doc, updateDoc } from 'firebase/firestore';
import { db, dbStates, dbUser } from '../../firebase';
import { State } from '../LoggedIn';
import { useLocation, useNavigate } from 'react-router-dom';
import pathScore from '../../assets/path-score.svg';
import { getFieldsOfStudy, getSchools, FieldOfStudyWithClasses, School } from '../../common/storage';
import ClassChip, { ChipSize } from '../UserInfo/FavoriteClasses/ClassChip';
import { logButtonClick, logPageView } from '../../common/analytics';
import back from '../../assets/back-black.svg';
import { RoutesEnum } from '../routes';

const pageName = 'program';

function ProgramDetails() {
  const navigate = useNavigate();

  const [fieldOfStudy, setFieldOfStudy] = useState({} as FieldOfStudyWithClasses);
  const [school, setSchool] = useState({} as School);

  const { state } = useLocation();
  const program: ProgramModel = state.program;
  const user: User = state.user;
  const [saved, setSaved] = useState(
    !!user?.savedPrograms?.find((pr: ProgramModel) => pr.unique === program.unique) || false
  );

  useEffect(() => {
    logPageView(pageName);
  }, []);

  useEffect(() => {
    getSchools().then((res) => {
      let matchedSchool: School | undefined;
      matchedSchool = res.find((sc) => sc.school === program.school && sc.state === program.state)
      if (matchedSchool !== undefined) {
        setSchool(matchedSchool);
      }
    });
    getFieldsOfStudy().then((res) => {
      let matchedFieldOfStudy: FieldOfStudyWithClasses | undefined;
      matchedFieldOfStudy = res.find((fos) => fos.fieldOfStudy === program.fieldOfStudy)
      if (matchedFieldOfStudy !== undefined) {
        setFieldOfStudy(matchedFieldOfStudy);
      }
    });
  }, []);

  const updateSave = async (saved: boolean) => {
    logButtonClick('save', pageName);
    setSaved(saved);

    if (saved) {
      if (user.savedPrograms) {
        user.savedPrograms.push(program);
      } else {
        user.savedPrograms = [program];
      }
    } else {
      user.savedPrograms = user.savedPrograms.filter((pr: ProgramModel) => pr.unique !== program.unique);
    }

    await updateDoc(doc(db, dbUser, user.id), {
      savedPrograms: user.savedPrograms
    });
  };

  const getNumberWithOrdinal = (n: number): string => {
    var s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  const showStateCheckMark = (stateName: string): boolean => {
    const states = JSON.parse(window.sessionStorage.getItem(dbStates) || '') as State[];
    const state = states?.find((s) => s.state === stateName);
    if (!state) {
      return false;
    }
    const preferences = user.userInfo.geographicPreferences;
    return (
      preferences.regions.includes(state.region) ||
      preferences.subRegions.includes(state.subregion) ||
      preferences.states.includes(stateName)
    );
  };

  const getMatchingClasses = () => {
    if (!fieldOfStudy) {
      return <></>; // Return an empty component if the fieldOfStudy is not found
    }

    const matchingClasses = [fieldOfStudy.rank1, fieldOfStudy.rank2, fieldOfStudy.rank3, fieldOfStudy.rank4, fieldOfStudy.rank5].filter((className) => {
      return user.userInfo.classes?.length > 0 && user.userInfo.classes.map((selectedClass) => selectedClass.class).includes(className);
    });

    return (
      <>
        {matchingClasses.map((className) => (
          <ClassChip
            key={className}
            classText={className}
            selected={!!user.userInfo.classes.find((cl) => cl.class === className)}
          />
        ))}
      </>
    );
  };


  return (
    <div className="details-of-program">
      <div className="details-of-program--title d-flex justify-content-between">
        Here is how this program fits with your skills and interests.
        <img
          alt="Back"
          src={back}
          onClick={() => {
            logButtonClick('back', pageName);
            navigate(-1);
          }}
        />
      </div>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="header-wrapper w-100 d-flex flex-column">
              <div className="header d-flex justify-content-between">
                <div className="study d-flex flex-column">
                  <h2>
                    {program.school} ({getStateCode(program.state)})
                  </h2>
                  <span>
                    {program.fieldOfStudy} - {program.degree}
                  </span>
                </div>
                <div className="icons d-flex align-items-center">
                  {saved ? (
                    <img alt="Saved" src={favFilled} onClick={() => updateSave(false)} />
                   ) : (
                     <img alt="Not saved" src={fav} onClick={() => updateSave(true)} />
                   )}
                </div>
              </div>
            </div>
          </Accordion.Header>
          <div className="scores d-flex">
            <div 
              className="program-score d-flex justify-content-between align-items-center"
              onClick={(event) => {
                logButtonClick('program_score', pageName);
                event.stopPropagation();
                navigate(RoutesEnum.ProgramScoreInfo);
              }}
            >
              <div className="d-flex align-items-center">
                <label className='label-bold'>Program Score</label>
                <img alt="Info"src={info} />
              </div>
              <span className="score">{program.programScore}</span>
            </div>
            <div
              className="path-score d-flex justify-content-between align-items-center"
              onClick={(event) => {
                logButtonClick('path_score', pageName);
                event.stopPropagation();
                navigate(RoutesEnum.PathScoreInfo);
              }}
            >
              <div className="d-flex align-items-center">
                <label className='label-bold'>Path Score</label>
                <img alt="Info"src={infoDark} />
              </div>
              <span className="score">{program.pathScore}</span>
            </div>
          </div>
          <div className="money d-flex">
            <div className="price d-flex align-items-center">
              <label>Median Income:</label>
              <span>{program.incomePresented}</span>
            </div>
            <div className="price d-flex align-items-center">
              <label>Median Cost of Attendance:</label>
              <span>{program.price}</span>
            </div>
          </div>
          <Accordion.Body
            onEnter={() => logButtonClick('expand', pageName)}
            onExit={() => logButtonClick('collapse', pageName)}>
            <div className="info-of-section">
              {school?.schoolDescription}
            </div>
            <br />
            <div className="info-of-section">
              {fieldOfStudy?.description?.replace('This field', program.fieldOfStudy)}
            </div>
            <br />
            <div className="info-of-section">
              The financial outcomes for students admitted to a {program.degree} in {program.fieldOfStudy} from {program.school} rank in the {getNumberWithOrdinal(Math.floor(program.programScore))} percentile of all US programs.
            </div>
          </Accordion.Body>
        </Accordion.Item>
          <div className="details-of-program--wrapper-info">
            <div className="details-of-program--wrapper-info--likelihood-of-admission">
              <div className="d-flex align-items-end">
                <Badge className="value-of-section">
                  {program.schoolType}
                </Badge>
                <span className="label-of-section">Likelihood of Admission</span>
              </div>
              <div className="info-of-section">
                Based on your SAT score, your GPA and your level of extracurriculars, we estimate your likelihood of
                getting into {program.school} is: {program.admissionLikelihood}.
              </div>
            </div>
            <div className="details-of-program--wrapper-info--geographic-preferences">
              <div className="block d-flex align-items-center">
                <Badge className="value-of-section block d-flex align-items-center">
                  <span>{getStateCode(program.state)}</span>
                  {showStateCheckMark(program.state) && (
                    <img className="align-self-baseline checkmark" alt="Check" src={check} />
                  )}
                </Badge>
                <span className="label-of-section">Geographic Preferences</span>
              </div>
              <div className="info-of-section">
                {program.school} is located in {program.state} which {showStateCheckMark(program.state) ? ' is' : ' is not'} part of your selected geography.
              </div>
            </div>
            <div className="details-of-program--wrapper-info--interest-in-major">
              <div className="d-flex align-items-end">
                <Badge className="value-of-section">{program.interestInMajorScore}</Badge>
                <span className="label-of-section">Interest in Major</span>
              </div>
              <div className="interests d-flex flex-column">
                <div className="info-of-section">
                  {program.fieldOfStudy} matches your interest in the following classes:
                </div>
                <div className="matches d-flex flex-row">
                  {getMatchingClasses()}
                </div>
              </div>
            </div>
            {program.yearlyInfo && (
              <Table bordered>
                <tbody>
                  <tr>
                    <th>Median Income (Department of Education)</th>
                    <td>{program.yearlyInfo['1'].medianIncome !== 0 ? `$${Math.round(program.yearlyInfo['1'].medianIncome).toLocaleString("en-US")}` : "N/A"}</td>
                    <td>{program.yearlyInfo['2'].medianIncome !== 0 ? `$${Math.round(program.yearlyInfo['2'].medianIncome).toLocaleString("en-US")}` : "N/A"}</td>
                    <td>{program.yearlyInfo['3'].medianIncome !== 0 ? `$${Math.round(program.yearlyInfo['3'].medianIncome).toLocaleString("en-US")}` : "N/A"}</td>
                  </tr>
                  <tr>
                    <th>Estimated Median Income (Path Technologies)</th>
                    <td>${Math.round(program.yearlyInfo['1'].estimatedIncome).toLocaleString("en-US")}</td>
                    <td>${Math.round(program.yearlyInfo['2'].estimatedIncome).toLocaleString("en-US")}</td>
                    <td>${Math.round(program.yearlyInfo['3'].estimatedIncome).toLocaleString("en-US")}</td>
                  </tr>
                  <tr>
                    <th>Employment</th>
                    <td>{Math.round(program.yearlyInfo['1'].employment)}%</td>
                    <td>{Math.round(program.yearlyInfo['2'].employment)}%</td>
                    <td>{Math.round(program.yearlyInfo['3'].employment)}%</td>
                  </tr>
                  {/*
                  <tr>
                    <th>School Weighted Average Income</th>
                    <td>${Math.round(program.yearlyInfo['1'].schoolAverageIncome).toLocaleString("en-US")}</td>
                    <td>${Math.round(program.yearlyInfo['2'].schoolAverageIncome).toLocaleString("en-US")}</td>
                    <td>${Math.round(program.yearlyInfo['3'].schoolAverageIncome).toLocaleString("en-US")}</td>
                  </tr>
                  */}
                  <tr>
                    <th></th>
                    <td>Year 1</td>
                    <td>Year 2</td>
                    <td>Year 3</td>
                  </tr>
                </tbody>
              </Table>
            )}
            <Table bordered>
              <thead>
                <tr className="text-center">
                  <th>Income Score</th>
                  <th>Graduation Score</th>
                  <th>Payment Score</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td>{Math.round(program.incomeRank)}%</td>
                  <td>{Math.round(program.graduationScore)}%</td>
                  <td>{Math.round(program.repaymentScore)}%</td>
                </tr>
              </tbody>
            </Table>
          </div>
      </Accordion>
    </div>
  );
}

export default ProgramDetails;
