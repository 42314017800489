import back from '../../../assets/back-black.svg';
import { RoutesEnum } from '../../routes';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PathScore.scss';
import { HomeRoutesEnum } from '../../Base';
import navigStar from '../../../assets/navig-star.svg';
import { UserInfoRoutes } from '../../UserInfo/UserInfo';
import introduction from '../../../assets/introduction.svg';
import testScores from '../../../assets/test-scores.svg';
import interestsAndActivities from '../../../assets/interests-and-activities.svg';
import favoriteClasses from '../../../assets/favorite-classes.svg';
import costAndGeography from '../../../assets/cost-and-geography.svg';
import { Button } from 'react-bootstrap';
import { logButtonClick, logPageView, pageNameByUrl } from '../../../common/analytics';

function PathScore() {
  const navigate = useNavigate();
  const pageName = pageNameByUrl(location.pathname);

  useEffect(() => {
    logPageView(pageName);
  }, []);

  return (
    <div className="what-is path-score">
      <div className="title">
        <label>What’s a Path Score?</label>
        <img
          alt="Back"
          src={back}
          onClick={() => {
            logButtonClick('back', pageName);
            navigate(-1);
          }}
        />
      </div>
      <label className="description">
        A Path Score is a rating from A+ to D to show whether a college program is the right fit for you. We take into
        account both the program’s financial outcomes as well as you skills and interest.
      </label>
      <label className="description">
        Path Scores are individualized, meaning they’re calculated just for you and update as you update your
        preferences.
      </label>

      <div className="score-panel d-flex">
        <div
          className="d-flex justify-content-center align-items-center score-icon"
          onClick={() => navigate(HomeRoutesEnum.SavedPrograms)}>
          <img alt="Saved Programs" src={navigStar} />
        </div>
        <div
          className="d-flex justify-content-center align-items-center score-icon"
          onClick={() => navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.Introduction}`)}>
          <img alt="Introduction" src={introduction} />
        </div>
        <div
          className="d-flex justify-content-center align-items-center score-icon"
          onClick={() => navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.TestScores}`)}>
          <img alt="Test Scores" src={testScores} />
        </div>
        <div
          className="d-flex justify-content-center align-items-center score-icon"
          onClick={() => navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.Activities}`)}>
          <img alt="Interests And Activities" src={interestsAndActivities} />
        </div>
        <div
          className="d-flex justify-content-center align-items-center score-icon"
          onClick={() => navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.FavoriteClasses}`)}>
          <img alt="Favorite Classes" src={favoriteClasses} />
        </div>
        <div
          className="d-flex justify-content-center align-items-center score-icon"
          onClick={() => navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.CostAndGeography}`)}>
          <img alt="CostAndGeography" src={costAndGeography} />
        </div>
      </div>

      <div className="d-flex w-100 justify-content-center">
        <Button className="main-button">Path Score</Button>
      </div>
    </div>
  );
}

export default PathScore;
