import { UserInfo } from '../types';

export function mapUserInfo(userInfo: UserInfo) {
  const { geographicPreferences } = userInfo;

  let geoSelection = {};

  if (geographicPreferences) {
    if (geographicPreferences.regions && !geographicPreferences.subRegions && !geographicPreferences.states) {
      geoSelection = { geo_selection_region: geographicPreferences.regions };
    } else if (geographicPreferences.subRegions && !geographicPreferences.states) {
      geoSelection = { geo_selection_subregions: geographicPreferences.subRegions };
    } else if (geographicPreferences.states) {
      geoSelection = { geo_selection_states: geographicPreferences.states };
    }
  }

  return {
    cost_sensitivity: userInfo?.costSensitivity,
    favorite_class_score: userInfo.classes?.map((c) => c.value),
    favorite_classes: userInfo.classes?.map((c) => c.class),
    hours_activity: userInfo.activities?.map((activity) => activity.hours).reduce((a, b) => a + b) || 0,
    num_activity: userInfo.activities?.length || 0,
    sat_score: userInfo.scores?.satScore,
    state_residency: userInfo.state,
    ...geoSelection,
  };
}
