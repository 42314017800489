import React, { useEffect } from 'react';
import logo from '../assets/logo.svg';
import './Loading.scss';
import { logPageView } from '../common/analytics';

function Loading() {
  useEffect(() => {
    logPageView('loading');
  }, []);

  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100">
      <img alt="Logo" src={logo} width="70%" />
    </div>
  );
}

export default Loading;
