import biology from '../../../assets/biology.svg';
import chemistry from '../../../assets/chemistry.svg';
import physics from '../../../assets/physics.svg';
import computer from '../../../assets/computer.svg';
import algebra from '../../../assets/algebra.svg';
import calculus from '../../../assets/calculus.svg';
import geometry from '../../../assets/geometry.svg';
import statistics from '../../../assets/statistics.svg';
import geography from '../../../assets/geography.svg';
import economic from '../../../assets/economic.svg';
import history from '../../../assets/history.svg';
import humanities from '../../../assets/humanities.svg';
import languages from '../../../assets/languages.svg';
import written from '../../../assets/written.svg';
import literature from '../../../assets/literature.svg';
import visual from '../../../assets/visual.svg';
import performing from '../../../assets/performing.svg';
import music from '../../../assets/music.svg';
import physEd from '../../../assets/physEd.svg';
import health from '../../../assets/health.svg';
import { Classes } from './FavoriteClasses';

export function getIcon(className: string): string {
  switch (className) {
    case Classes.Science.Biology:
      return biology;
    case Classes.Science.Chemistry:
      return chemistry;
    case Classes.Science.Physics:
      return physics;
    case Classes.Science.Computer:
      return computer;
    case Classes.Math.Algebra:
      return algebra;
    case Classes.Math.Calculus:
      return calculus;
    case Classes.Math.Geometry:
      return geometry;
    case Classes.Math.Statistics:
      return statistics;
    case Classes.Social.Geography:
      return geography;
    case Classes.Social.Economics:
      return economic;
    case Classes.Social.History:
      return history;
    case Classes.Social.Humanities:
      return humanities;
    case Classes.English.Languages:
      return languages;
    case Classes.English.Written:
      return written;
    case Classes.English.Literature:
      return literature;
    case Classes.Arts.Visual:
      return visual;
    case Classes.Arts.Performing:
      return performing;
    case Classes.Arts.Music:
      return music;
    case Classes.Health.PhysEd:
      return physEd;
    case Classes.Health.Health:
      return health;
    default:
      return '';
  }
}
