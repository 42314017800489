import './Announcements.scss';
import ProgramsPageWrapper from '../Home/ProgramsPageWrapper';
import { User } from '../../types';
import logo from '../../assets/logo.svg';

function Announcements({ user }: { user: User }) {
  return (
    <ProgramsPageWrapper user={user}>
      <div className="wrapper">
        <img src={logo} alt="Path Logo" className="logo" />
        <h1 className="title">Coming Soon!</h1>
      </div>
    </ProgramsPageWrapper>
  );
}

export default Announcements;
