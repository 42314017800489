import ProgramsPageWrapper from '../Home/ProgramsPageWrapper';
import { User } from '../../types';
import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import '../Home/Home.scss';
import './Explore.scss';
import Program from './Program';
import School from './School/School';
import FieldOfStudy from './FieldOfStudy/FieldOfStudy';
import { Swiper as SwiperClass } from 'swiper/types';
import { logButtonClick, logPageView } from '../../common/analytics';

function Explore({ user }: { user: User }) {
  useEffect(() => {
    logPageView('explore_program');
  }, []);

  const swipeHandler = (swipe: SwiperClass) => {
    switch (swipe.previousIndex) {
      case 1:
        logButtonClick('scroll', 'explore_school');
        break;
      case 2:
        logButtonClick('scroll', 'explore_field_of_study');
        break;
      default:
        logButtonClick('scroll', 'explore_program');
        break;
    }

    switch (swipe.activeIndex) {
      case 1:
        return logPageView('explore_school');
      case 2:
        return logPageView('explore_field_of_study');
      default:
        return logPageView('explore_program');
    }
  };

  return (
    <ProgramsPageWrapper user={user}>
      <Swiper>
        <SwiperSlide>
          <Program user={user} />
        </SwiperSlide>
      </Swiper>
      {/*
        <Swiper onSlideChange={swipeHandler} pagination={true} modules={[Pagination]}>
          <SwiperSlide>
            <Program user={user} />
          </SwiperSlide>
          <SwiperSlide>
            <School user={user} />
          </SwiperSlide>
          <SwiperSlide>
            <FieldOfStudy user={user} />
          </SwiperSlide>
        </Swiper>
      */}
    </ProgramsPageWrapper>
  );
}

export default Explore;
