import { Route, Routes, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { auth, db, dbFieldsOfStudy, dbStates, dbUser } from '../firebase';
import Loading from '../components/Loading';
import { onAuthStateChanged } from 'firebase/auth';
import UserInfo from './UserInfo/UserInfo';
import Base from './Base';
import { RoutesEnum } from './routes';
import { addDoc, collection, getDocs, limit, query, where, FieldPath } from 'firebase/firestore';
import { User } from '../types';

export interface State {
  abbreviation: string;
  region: string;
  state: string;
  subregion: string;
}

function LoggedIn() {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  async function checkUser() {
    if (!auth.currentUser || !loading) {
      return;
    }

    const queryUser = query(
      collection(db, dbUser),
      where(new FieldPath('userInfo', 'email'), '==', auth.currentUser.email),
      limit(1)
    );
    const snapshot = await getDocs(queryUser);
    if (!snapshot.docs.length) {
      await addDoc(collection(db, dbUser), {
        userInfo: {
          email: auth.currentUser.email
        }
      } as User);
      navigate(RoutesEnum.UserInfo);
    }

    setLoading(false);
  }

  useEffect(() => {
    if (!auth.currentUser) {
      onAuthStateChanged(auth, (user) => {
        if (!user) {
          navigate('/login');
        } else {
          checkUser();
        }
      });
    } else {
      checkUser();
    }

    if (!window.sessionStorage.getItem(dbStates)?.length) {
      const queryStates = query(collection(db, dbStates));
      getDocs(queryStates).then((snapshot) => {
        const states = snapshot.docs.map((doc) => {
          return { ...doc.data() } as State;
        });
        window.sessionStorage.setItem(dbStates, JSON.stringify(states));
      });
    }
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <Routes>
      <Route path={`${RoutesEnum.UserInfo}/*`} element={<UserInfo />} />
      <Route path="*" element={<Base />} />
    </Routes>
  );
}

export default LoggedIn;
