import PageWrapper from '../PageWrapper';
import { Button, Form, FormLabel } from 'react-bootstrap';
import './TestScores.scss';
import { useUserInfoScoresHook } from '../../../hooks/userInfoScoresHook';
import { useEffect } from 'react';
import { logButtonClick, logPageView } from '../../../common/analytics';

export const testScoresPageName = 'onboarding_test_scores';

function TestScores() {
  const {
    addScore,
    addUnweightedGPA,
    addWeightedGPA,
    handleClickActButton,
    handleClickNextButton,
    handleClickSatButton,
    hasOnboarded,
    isActSelected,
    isSatSelected,
    nextPage,
    prevPage,
    score,
    testScoreInputError,
    unweightedGPA,
    unweightGpaInputError,
    weightedGPA,
    weightGpaInputError
  } = useUserInfoScoresHook();
  const satBtnClasses = `test-scores--button me-2 ${isSatSelected ? 'test-scores--button--active' : ''}`;
  const actBtnClasses = `test-scores--button ${isActSelected ? 'test-scores--button--active' : ''}`;

  useEffect(() => {
    logPageView(testScoresPageName);
  }, []);

  // eslint-disable-next-line
  return (
    <PageWrapper>
      <Form className="test-scores d-flex flex-column">
        <p className="description">
          To find the right school, we first need some information about your test scores and GPA. If you don’t know yet, enter your target score! Don’t worry, you can always come back and change it later.
        </p>
        <Form.Group className="mb-2">
          <div className="d-flex justify-content-around mt-3 mb-2">
            <Button className={satBtnClasses} id="isSatSelected" onClick={handleClickSatButton}>
              SAT
            </Button>
            <h2 className="header d-flex">
              OR
            </h2>
            <Button className={actBtnClasses} id="isActSelected" onClick={handleClickActButton}>
              ACT
            </Button>
          </div>
          {isSatSelected && <Form.Control
            className="test-scores--input test-scores--input--test-score"
            name="testScore"
            type="number"
            placeholder="SCORE"
            id="testScoreInput"
            value={score}
            onChange={(event) => addScore(+event.target.value, event)}
            min="400"
            max={isSatSelected ? 1600 : 36}></Form.Control>}
          {isActSelected && <p className="description d-flex justify-content-center">COMING SOON</p>}
          {testScoreInputError && <div className="error">{testScoreInputError}</div>}
        </Form.Group>
        <p className="description">
          Now how about your GPA? We consider unweighted GPA out of 4.0 and weighted GPA out of 5.0. A best guess is just fine!
        </p>
        <Form.Group>
          <FormLabel>Enter Your High School GPA</FormLabel>
          <div className="d-flex justify-content-around mt-3 mb-2">
            <div className="me-2">
              <Form.Control
                className="test-scores--input test-scores--input--test-score"
                id="unweightGpaInput"
                max="4"
                min="0.0"
                name="unweightGpa"
                onChange={(event) => addUnweightedGPA(event)}
                step="0.1"
                type="number"
                value={unweightedGPA}
              / >
              {unweightGpaInputError && (
                <div className="error test-scores--gpa-error-text">{unweightGpaInputError}</div>
              )}
              <div className="test-scores--input--gpa--label text-center">Unweighted GPA</div>
            </div>
            <h2 className="header d-flex">
              OR
            </h2>
            <div>
              <Form.Control
                className="test-scores--input test-scores--input--test-score"
                id="weightGpaInput"
                max="5"
                min="0.0"
                name="weightGpa"
                onChange={(event) => addWeightedGPA(event)}
                step="0.1"
                type="number"
                value={weightedGPA}
              / >
              {weightGpaInputError && <div className="error test-scores--gpa-error-text">{weightGpaInputError}</div>}
              <div className="test-scores--input--gpa--label text-center">Weighted GPA</div>
            </div>
          </div>
        </Form.Group>
      </Form>
      <>
        <Button
          className="back"
          variant="link"
          id="backButton"
          onClick={() => {
            logButtonClick('back', testScoresPageName);
            prevPage();
          }}>
          {hasOnboarded ? 'Home' : 'Back'}
        </Button>
        <Button
          className="submit"
          variant="link"
          id="nextButton"
          onClick={handleClickNextButton}
          disabled={!score || !!testScoreInputError || !!unweightGpaInputError || !!weightGpaInputError}>
          {hasOnboarded ? 'Update' : 'Next'}
        </Button>
      </>
    </PageWrapper>
  );
}

export default TestScores;
