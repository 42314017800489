import { User } from '../../types';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import Program, { ProgramModel } from '../../components/Program';
import { Form, InputGroup } from 'react-bootstrap';
import search from '../../assets/search.svg';
import clear from '../../assets/clear.svg';
import refresh from '../../assets/regresh.svg';
import axios from 'axios';
import { mapUserInfo } from '../../common/mapUser';
import { buildProgramByResponse, ProgramApiModel } from '../../common/buildProgram';
import { logButtonClick, logInput } from '../../common/analytics';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useNavigate } from 'react-router-dom';
import { getFieldsOfStudy, getSchools, FieldOfStudyWithClasses } from '../../common/storage';

const pageName = 'explore_program';

function SchoolMajor({ user }: { user: User }) {
  const navigate = useNavigate();

  const [programs, setPrograms] = useState([] as ProgramModel[]);

  const fieldOfStudyRef = useRef(null);
  const [fieldOfStudySearch, setFieldOfStudySearch] = useState([] as Option[]);
  const [majors, setMajors] = useState([] as FieldOfStudyWithClasses[]);

  const schoolRef = useRef(null);
  const [schoolSearch, setSchoolSearch] = useState([] as Option[]);
  const [schoolList, setSchoolList] = useState([] as string[]);

  const requestData = async () => {
    const res = await axios.post('https://modelapi.path-technologies.com/api/get_program_selections', {
      ...mapUserInfo(user.userInfo),
      max_same_school: 1,
      max_same_field_of_study: 1,
      num_reach: 3,
      num_safety: 3,
      num_target: 4,
      program_subset: fieldOfStudySearch,
      school_subset: schoolSearch
    });

    const fieldOfStudyInputValue = (fieldOfStudyRef as MutableRefObject<any>).current.state.selected;
    const schoolInputValue = (schoolRef as MutableRefObject<any>).current.state.selected;

    if (
      ((fieldOfStudyInputValue.length === 0 && fieldOfStudySearch.length === 0) ||
        fieldOfStudyInputValue[0] === fieldOfStudySearch[0]) &&
      ((schoolInputValue.length === 0 && schoolSearch.length === 0) || schoolInputValue[0] === schoolSearch[0])
    ) {
      setPrograms(res.data.map((data: ProgramApiModel) => buildProgramByResponse(data)));
    }
  };

  useEffect(() => {
    getFieldsOfStudy().then((res) => {
      setMajors(res);
    });
    getSchools().then((res) => {
      setSchoolList(res.map((sc) => sc.school));
    });
  }, []);

  useEffect(() => {
    if (user?.userInfo) {
      requestData();
    }
  }, [user, fieldOfStudySearch, schoolSearch]);

  const onRefresh = async () => {
    logButtonClick('refresh', pageName);
    requestData();
  };

  return (
    <div className="school-major-container programs-container">
      <div className="title">
        <h1>Search by Program</h1>
        <Form className="d-flex flex-column">
          <InputGroup className="search-field">
            <InputGroup.Text className="search-icon">
              <img alt="Icon" src={search} />
            </InputGroup.Text>
            <Typeahead
              ref={schoolRef}
              id="school"
              onChange={(term) => {
                logInput('field_of_study', pageName, (term[0] || '').toString());
                setSchoolSearch(term);
              }}
              options={schoolList}
              placeholder="School"
              selected={schoolSearch}
            />
            <InputGroup.Text className="clear-icon">
              <img
                alt="Clear"
                src={clear}
                onClick={() => {
                  logButtonClick('clear', pageName);
                  (schoolRef as MutableRefObject<any>).current?.clear();
                  setSchoolSearch([]);
                }}
              />
            </InputGroup.Text>
          </InputGroup>
          <InputGroup className="search-field">
            <InputGroup.Text className="search-icon">
              <img alt="Icon" src={search} />
            </InputGroup.Text>
            <Typeahead
              ref={fieldOfStudyRef}
              id="fieldOfStudy"
              onChange={(term) => {
                logInput('field_of_study', pageName, (term[0] || '').toString());
                setFieldOfStudySearch(term);
              }}
              options={majors.map((m) => m.fieldOfStudy)}
              placeholder="Field of study"
              selected={fieldOfStudySearch}
            />
            <InputGroup.Text className="clear-icon">
              <img
                alt="Clear"
                src={clear}
                onClick={() => {
                  logButtonClick('clear', pageName);
                  (fieldOfStudyRef as MutableRefObject<any>).current?.clear();
                  setFieldOfStudySearch([]);
                }}
              />
            </InputGroup.Text>
          </InputGroup>
        </Form>
        <div className="d-flex justify-content-between align-items-center popular-programs-line">
          <label className="description">
            More programs for you to explore:
          </label>
          <img alt="Refresh" src={refresh} onClick={onRefresh} />
        </div>
      </div>

      <div className="programs">
        {user?.userInfo &&
          programs.map((program) => {
            return <Program key={program.unique} program={program} user={user}></Program>;
          })}
      </div>
    </div>
  );
}

export default SchoolMajor;
