import React from 'react';
import './Auth.scss';

function AuthHeader() {
  return (
    <div className="header align-self-center">
      <h1>Welcome Explorer</h1>
    </div>
  );
}

export default AuthHeader;
