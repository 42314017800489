import back from '../../../assets/back-black.svg';
import { RoutesEnum } from '../../routes';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProgramScore.scss';
import graduationRate from '../../../assets/graduation-rate.svg';
import timeToGraduation from '../../../assets/time-to-graduation.svg';
import employmentRate from '../../../assets/employment-rate.svg';
import earlyCareerIncome from '../../../assets/early-career-income.svg';
import defaultRate from '../../../assets/default-rate.svg';
import repaymentRate from '../../../assets/repayment-rate.svg';
import arrowDown from '../../../assets/arrow-down.svg';
import { Button } from 'react-bootstrap';
import { logButtonClick, logPageView, pageNameByUrl } from '../../../common/analytics';

function ProgramScore() {
  const navigate = useNavigate();
  const pageName = pageNameByUrl(location.pathname);

  useEffect(() => {
    logPageView(pageName);
  }, []);

  return (
    <div className="what-is program-score">
      <div className="title">
        <label>What’s a Program Score?</label>
        <img
          alt="Back"
          src={back}
          onClick={() => {
            logButtonClick('back', pageName);
            navigate(-1);
          }}
        />
      </div>
      <label className="description">
        A Program Score is a number between 1 and 100 that represents the financial outcomes of a college program
        (meaning a combination of school, major and degree level).
      </label>
      <label className="description">We account for all the important data so that you don’t have to!</label>
      <div className="school-major d-flex">
        <div className="block">School</div>
        <label className="plus">+</label>
        <div className="block">Major</div>
        <label className="plus">+</label>
        <div className="block">Degree</div>
      </div>
      <div className="program-score-row d-flex">
        <div className="left-block">
          <img alt="Icon" src={graduationRate} />
          <label>Graduation rate</label>
        </div>
        <div className="right-block">
          <img alt="Icon" src={timeToGraduation} />
          <label>Time to graduation</label>
        </div>
      </div>
      <div className="program-score-row d-flex">
        <div className="left-block">
          <img alt="Icon" src={employmentRate} />
          <label>Employment rate</label>
        </div>
        <div className="right-block">
          <img alt="Icon" src={earlyCareerIncome} />
          <label>Early career income</label>
        </div>
      </div>
      <div className="program-score-row d-flex">
        <div className="left-block">
          <img alt="Icon" src={defaultRate} />
          <label>Default rate</label>
        </div>
        <div className="right-block">
          <img alt="Icon" src={repaymentRate} />
          <label>Repayment rate</label>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="d-flex justify-content-center align-items-center arrow-down-container">
          <img alt="Arrow down" src={arrowDown} />
        </div>
      </div>
      <div className="d-flex w-100 justify-content-center">
        <Button className="main-button">Program Score</Button>
      </div>
      <label className="explainer">
        Unlike traditional college rankings, we don’t account for things that don’t matter and our scores can’t be gamed
        by the schools. The only way for schools to improve is for their students to have better financial outcomes.
      </label>
    </div>
  );
}

export default ProgramScore;
