import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase';
import { NavLink } from 'react-router-dom';
import AuthHeader from './AuthHeader';
import { Button, Form } from 'react-bootstrap';
import { getFirebaseErrorText, validateEmail } from './Validation';
import firebase from 'firebase/compat/app';
import FirebaseError = firebase.FirebaseError;

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState('');

  const triggerResetEmail = async () => {
    try {
      const err = validateEmail(email);
      setError(err);
      if (err) {
        return;
      }

      await sendPasswordResetEmail(auth, email);
      setEmail('');
      setError('');
      setEmailSent(true);
    } catch (error) {
      setError(getFirebaseErrorText(error as FirebaseError));
      throw error;
    }
  };

  return (
    <div className="auth d-flex flex-column justify-content-between h-100">
      <AuthHeader />
      <Form className="d-flex flex-column">
        <Form.Group>
          <Form.Label htmlFor="email-address">Email address</Form.Label>
          <Form.Control
            id="email-address"
            name="email"
            type="email"
            required
            placeholder="Email address"
            className={error ? 'invalid' : ''}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailSent && !error && <div className="valid">Password reset email sent</div>}
          {error && <div className="error">{error}</div>}
        </Form.Group>

        <Button className="main-button" type="button" onClick={triggerResetEmail}>
          Reset password
        </Button>
        <label className="sign-in-label text-center">
          Return to <NavLink to="/login">Sign in</NavLink>
        </label>
      </Form>
      <div className="footer align-self-center">
        <h2>Find your path</h2>
        <label>Explore colleges and majors Understand career outcomes Find your college program match</label>
      </div>
    </div>
  );
}

export default ResetPassword;
