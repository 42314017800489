import { logEvent } from 'firebase/analytics';
import { analytics, auth, sessionIdKey } from '../firebase';
import { RoutesEnum } from '../pages/routes';

export function logPageView(name: string) {
  logEvent(analytics, 'page_view', {
    name,
    session: localStorage.getItem(sessionIdKey),
    user: auth.currentUser?.uid
  });
}

export function logInput(name: string, page: string, value: string, type?: string, screen?: string) {
  logEvent(analytics, 'input', {
    name,
    page,
    value,
    type,
    screen,
    session: localStorage.getItem(sessionIdKey),
    user: auth.currentUser?.uid
  });
}

export function logButtonClick(name: string, page: string, type?: string, screen?: string) {
  logEvent(analytics, 'button_click', {
    name,
    page,
    type,
    screen,
    session: localStorage.getItem(sessionIdKey),
    user: auth.currentUser?.uid
  });
}

export function pageNameByUrl(url: string) {
  const elems = url.split('/');
  const lastElem = elems[elems.length - 1];
  switch (url) {
    case RoutesEnum.ProgramScoreInfo:
      return 'program_score';
    case RoutesEnum.PathScoreInfo:
      return 'path_score';
    case RoutesEnum.Explore:
      return 'explore_program';
    default:
      return lastElem;
  }
}
