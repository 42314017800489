import { ActivityMap } from './Activities';
import './ActivityChip.scss';
import { useEffect, useState } from 'react';
import { getIcon } from './Icons';

export interface ActivityChipProps {
  text: string;
  selected?: boolean;
  chipSize?: ChipSize;
  onClick?: (selected: boolean) => void;
}

export enum ChipSize {
  Small = 'small',
  Default = '',
  Big = 'big'
}

function ActivityChip({ text, selected = false, chipSize = ChipSize.Default, onClick }: ActivityChipProps) {
  const [isSelected, setIsSelected] = useState(selected);
  const getSectionName = () => {
    let result = '';
    Object.keys(ActivityMap).forEach((section) => {
      if (result) {
        return;
      }
      Object.keys((ActivityMap as any)[section]).forEach((activityText) => {
        if (result) {
          return;
        }
        if ((ActivityMap as any)[section][activityText] === text) {
          result = section.toLowerCase();
        }
      });
    });

    return result;
  };

  useEffect(() => {
    setIsSelected(selected);
  }, []);

  const clickHandler = () => {
    if (onClick) {
      setIsSelected(!isSelected);
      onClick(!isSelected);
    }
  };

  return (
    <div
      onClick={clickHandler}
      className={`d-flex justify-content-between activity-chip ${getSectionName()} ${
        isSelected ? 'selected' : ''
      } ${chipSize}`}>
      <span>{text}</span>
      <img alt="logo" src={getIcon(text)} />
    </div>
  )
}

export default ActivityChip;
