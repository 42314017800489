import PageWrapper from '../PageWrapper';
import { Button, Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { RoutesEnum } from '../../routes';
import { getUser, UserInfoRoutes } from '../UserInfo';
import { useNavigate } from 'react-router-dom';
import './Activities.scss';
import { ActivityModel } from '../../../types';
import okButton from '../../../assets/ok-button.svg';
import addButton from '../../../assets/add-button.svg';
import deleteButton from '../../../assets/delete.svg';
import ActivityChip, { ChipSize } from './ActivityChip';
import { db, dbUser } from '../../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { logButtonClick, logInput, logPageView } from '../../../common/analytics';
import { fetchProgramSelections } from '../../../common/buildProgram';
import { UserInfo } from '../../../types';

const pageName = 'activities';

export const ActivityMap = {
  Academic: {
    'Creative Writing': 'Creative Writing',
    Debate: 'Debate',
    Journalism: 'Journalism',
    Mentorship: 'Mentorship'
  },
  Athletic: {
    Coaching: 'Coaching',
    'Individual Sports': 'Individual Sports',
    'Outdoor Activities': 'Outdoor Activities',
    'Team Sports': 'Team Sports'
  },
  Arts: {
    Dance: 'Dance',
    Drama: 'Drama',
    Music: 'Music',
    Visual: 'Visual'
  },
  Community: {
    Clubs: 'Clubs',
    Environment: 'Environment',
    'Student Gov': 'Student Gov',
    Volunteer: 'Volunteer'
  },
  Scientific: {
    Coding: 'Coding',
    Engineering: 'Engineering',
    Research: 'Research',
    Robotics: 'Robotics'
  }
};

const hoursCount = 20;

function Activities() {
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [activities, setActivities] = useState([] as ActivityModel[]);
  const [hasOnboarded, setHasOnboarded] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState([] as string[]);
  const [isInvalidRows, setIsInvalidRows] = useState([] as boolean[]);
  const [sum, setSum] = useState(0);

  useEffect(() => {
    logPageView(pageName);
    getUser().then((user) => {
      setId(user.id);
      setUserInfo(user.userInfo);
      setHasOnboarded(user.userInfo?.hasOnboarded || false);

      const newActivities = user.userInfo.activities || [];
      setActivities(newActivities);
      setIsInvalidRows(newActivities.map((activity) => !areHoursValid(activity.hours)));
      checkSum(newActivities);
    });
  }, []);

  const clickHandler = (activityName: string, selected: boolean) => {
    if (selected) {
      setSelectedActivities((current) => [...current, activityName]);
    } else {
      setSelectedActivities(selectedActivities.filter((selectedActivity) => selectedActivity !== activityName));
    }
  };

  const addHandler = () => {
    logButtonClick('done', pageName, '', 'select');
    let newActivities = selectedActivities.map((activityName) => {
      logInput('select', pageName, activityName, '', 'select');
      const hours = activities.find((act) => act.type === activityName)?.hours;
      return { type: activityName, hours: hours || 0 } as ActivityModel;
    });

    setIsInvalidRows(newActivities.map((act) => !areHoursValid(act.hours)));

    setActivities(newActivities);
    setIsSelecting(false);
  };

  const openSelectHandler = () => {
    logButtonClick('select', pageName);
    setSelectedActivities(activities.map((act) => act.type));
    setIsSelecting(true);
  };

  const isSelected = (activityName: string) => {
    return !!activities.find((act) => act.type === activityName);
  };

  const setHours = (value: string, index: number) => {
    const newHours = value ? +value : null;
    const newActivities = activities.map((act, i) => {
      if (i !== index) {
        return act;
      } else {
        act.hours = newHours;
        return act;
      }
    });
    setActivities(newActivities);
    checkSum(newActivities);

    isInvalidRows[index] = !areHoursValid(newHours);
    setIsInvalidRows(isInvalidRows);
  };

  const checkSum = (checkActivities: ActivityModel[]) => {
    let sum = 0;
    checkActivities.forEach((act) => (sum += act.hours || 0));
    setSum(sum);
  };

  const areHoursValid = (value: number | null): boolean => {
    return !!value && value > 0;
  };

  const deleteActivity = (index: number) => {
    const newActivities = activities.filter((act, i) => i !== index);
    setActivities(newActivities);
    checkSum(newActivities);
  };

  const nextHandler = async () => {
    activities.forEach((act) => {
      logInput('hours', pageName, act?.hours?.toString() || '', act.type);
    });
    logButtonClick('next', pageName);

    const newUserInfo = {
      ...userInfo,
      activities
    } as UserInfo;

    let topPrograms;
    if (hasOnboarded) {
      topPrograms = await fetchProgramSelections(newUserInfo);
    }

    await updateDoc(doc(db, dbUser, id),  {
      ...(!hasOnboarded ? {} : { topPrograms }),
      userInfo: newUserInfo
    });

    navigate(hasOnboarded ? RoutesEnum.Home : `${RoutesEnum.UserInfo}${UserInfoRoutes.CostAndGeography}`);
  };

  return (
    <PageWrapper>
      {isSelecting ? (
        <div className="activity-select">
          <h1>Click on an activity to select it</h1>
          <div className="categories d-flex justify-content-center">
            <div className="category d-flex flex-column align-items-center">
              <h2 className="academic">Academic</h2>
              <ActivityChip
                text={ActivityMap.Academic['Creative Writing']}
                selected={isSelected(ActivityMap.Academic['Creative Writing'])}
                onClick={(selected) => clickHandler(ActivityMap.Academic['Creative Writing'], selected)}
              />
              <ActivityChip
                text={ActivityMap.Academic.Debate}
                selected={isSelected(ActivityMap.Academic.Debate)}
                onClick={(selected) => clickHandler(ActivityMap.Academic.Debate, selected)}
              />
              <ActivityChip
                text={ActivityMap.Academic.Journalism}
                selected={isSelected(ActivityMap.Academic.Journalism)}
                onClick={(selected) => clickHandler(ActivityMap.Academic.Journalism, selected)}
              />
              <ActivityChip
                text={ActivityMap.Academic.Mentorship}
                selected={isSelected(ActivityMap.Academic.Mentorship)}
                onClick={(selected) => clickHandler(ActivityMap.Academic.Mentorship, selected)}
              />
            </div>
            <div className="category d-flex flex-column align-items-center">
              <h2 className="athletic">Athletic</h2>
              <ActivityChip
                text={ActivityMap.Athletic.Coaching}
                selected={isSelected(ActivityMap.Athletic.Coaching)}
                onClick={(selected) => clickHandler(ActivityMap.Athletic.Coaching, selected)}
              />
              <ActivityChip
                text={ActivityMap.Athletic['Individual Sports']}
                selected={isSelected(ActivityMap.Athletic['Individual Sports'])}
                onClick={(selected) => clickHandler(ActivityMap.Athletic['Individual Sports'], selected)}
              />
              <ActivityChip
                text={ActivityMap.Athletic['Outdoor Activities']}
                selected={isSelected(ActivityMap.Athletic['Outdoor Activities'])}
                onClick={(selected) => clickHandler(ActivityMap.Athletic['Outdoor Activities'], selected)}
              />
              <ActivityChip
                text={ActivityMap.Athletic['Team Sports']}
                selected={isSelected(ActivityMap.Athletic['Team Sports'])}
                onClick={(selected) => clickHandler(ActivityMap.Athletic['Team Sports'], selected)}
              />
            </div>
            <div className="category d-flex flex-column align-items-center">
              <h2 className="arts">Arts</h2>
              <ActivityChip
                text={ActivityMap.Arts.Dance}
                selected={isSelected(ActivityMap.Arts.Dance)}
                onClick={(selected) => clickHandler(ActivityMap.Arts.Dance, selected)}
              />
              <ActivityChip
                text={ActivityMap.Arts.Drama}
                selected={isSelected(ActivityMap.Arts.Drama)}
                onClick={(selected) => clickHandler(ActivityMap.Arts.Drama, selected)}
              />
              <ActivityChip
                text={ActivityMap.Arts.Music}
                selected={isSelected(ActivityMap.Arts.Music)}
                onClick={(selected) => clickHandler(ActivityMap.Arts.Music, selected)}
              />
              <ActivityChip
                text={ActivityMap.Arts.Visual}
                selected={isSelected(ActivityMap.Arts.Visual)}
                onClick={(selected) => clickHandler(ActivityMap.Arts.Visual, selected)}
              />
            </div>
            <div className="category d-flex flex-column align-items-center">
              <h2 className="community">Community</h2>
              <ActivityChip
                text={ActivityMap.Community.Clubs}
                selected={isSelected(ActivityMap.Community.Clubs)}
                onClick={(selected) => clickHandler(ActivityMap.Community.Clubs, selected)}
              />
              <ActivityChip
                text={ActivityMap.Community.Environment}
                selected={isSelected(ActivityMap.Community.Environment)}
                onClick={(selected) => clickHandler(ActivityMap.Community.Environment, selected)}
              />
              <ActivityChip
                text={ActivityMap.Community['Student Gov']}
                selected={isSelected(ActivityMap.Community['Student Gov'])}
                onClick={(selected) => clickHandler(ActivityMap.Community['Student Gov'], selected)}
              />
              <ActivityChip
                text={ActivityMap.Community.Volunteer}
                selected={isSelected(ActivityMap.Community.Volunteer)}
                onClick={(selected) => clickHandler(ActivityMap.Community.Volunteer, selected)}
              />
            </div>
            <div className="category d-flex flex-column align-items-center">
              <h2 className="scientific">Scientific</h2>
              <ActivityChip
                text={ActivityMap.Scientific.Coding}
                selected={isSelected(ActivityMap.Scientific.Coding)}
                onClick={(selected) => clickHandler(ActivityMap.Scientific.Coding, selected)}
              />
              <ActivityChip
                text={ActivityMap.Scientific.Engineering}
                selected={isSelected(ActivityMap.Scientific.Engineering)}
                onClick={(selected) => clickHandler(ActivityMap.Scientific.Engineering, selected)}
              />
              <ActivityChip
                text={ActivityMap.Scientific.Research}
                selected={isSelected(ActivityMap.Scientific.Research)}
                onClick={(selected) => clickHandler(ActivityMap.Scientific.Research, selected)}
              />
              <ActivityChip
                text={ActivityMap.Scientific.Robotics}
                selected={isSelected(ActivityMap.Scientific.Robotics)}
                onClick={(selected) => clickHandler(ActivityMap.Scientific.Robotics, selected)}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="activities d-flex flex-column justify-content-between">
          <div>
            <label className="description">What about your interests? Some schools will consider these in your applications. We can use them to find the right program for you.</label>
            <label className="description">
              We’ve given you up to {hoursCount} hours to spend across the activities that you participate in outside of class. Spend wisely, but remember you can always come back later to update the activities and hours.
            </label>
            <h1>
              You’ve got <span>{hoursCount - sum}</span> points to spend!
            </h1>
            <div className="d-flex flex-column align-items-center">
              <div className="selected-activities d-flex flex-column align-items-center">
                {activities?.length == 0 && 
                  <p className="description">
                    First, click on the green button to select your activities.
                  </p>
                }
                {activities?.length > 0 && 
                  <div className="headers d-flex justify-content-between">
                    <span>Activity</span>
                    <span>Hours</span>
                  </div>
                }
                {activities?.length > 0 &&
                  activities.map((selectedActivity, index) => (
                    <div key={selectedActivity.type} className="activity-row d-flex justify-content-between w-100">
                      <ActivityChip text={selectedActivity.type} selected={true} chipSize={ChipSize.Big}></ActivityChip>
                      <Form.Control
                        type="number"
                        required
                        value={selectedActivity.hours || selectedActivity.hours === 0 ? selectedActivity.hours : ''}
                        min="0"
                        className={isInvalidRows[index] || sum > hoursCount ? 'invalid' : ''}
                        onChange={(e) => setHours(e.target.value, index)}
                      />
                      <img
                        className="delete-button"
                        alt="Delete"
                        src={deleteButton}
                        onClick={() => {
                          logButtonClick('remove', pageName, selectedActivity.type);
                          deleteActivity(index);
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center flex-column">
            <img className="add-button" alt="Add" src={addButton} onClick={() => openSelectHandler()} />
            <div className="space-div"></div>
          </div>
        </div>
      )}
      {isSelecting ? (
        <>
          <Button
            className="back"
            variant="link"
            id="backButton"
            onClick={() => {
              logButtonClick('back', pageName, '', 'select');
              setIsSelecting(false);
            }}>
            Back
          </Button>
          <img alt="Ok" src={okButton} onClick={() => addHandler()} />
        </>
      ) : (
        <>
          <Button
            className="back"
            variant="link"
            id="backButton"
            onClick={() => {
              logButtonClick('back', pageName);
              navigate(hasOnboarded ? RoutesEnum.Home : `${RoutesEnum.UserInfo}${UserInfoRoutes.Activities}`);
            }}>
            {hasOnboarded ? 'Home' : 'Back'}
          </Button>
          {!hasOnboarded && 
            <Button
              className="skip"
              variant="link"
              id="skipButton"
              onClick={() => {
                logButtonClick('skip', pageName);
                navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.CostAndGeography}`);
              }}>
              Skip
            </Button>
          }
          <Button
            className="submit"
            disabled={sum !== hoursCount || isInvalidRows.find((row) => row)}
            variant="link"
            id="nextButton"
            onClick={() => nextHandler()}>
            {hasOnboarded ? 'Update' : 'Next'}
          </Button>
        </>
      )}
    </PageWrapper>
  );
}

export default Activities;
