import journalism from '../../../assets/journalism.svg';
import mentorship from '../../../assets/mentorship.svg';
import coaching from '../../../assets/coaching.svg';
import computer from '../../../assets/computer.svg';
import individual from '../../../assets/individual-sports.svg';
import outdoors from '../../../assets/outdoor-activities.svg';
import dance from '../../../assets/dance.svg';
import clubs from '../../../assets/clubs.svg';
import environment from '../../../assets/environment.svg';
import leadership from '../../../assets/leadership.svg';
import volunteer from '../../../assets/volunteer.svg';
import engineering from '../../../assets/engineering.svg';
import languages from '../../../assets/languages.svg';
import written from '../../../assets/written.svg';
import research from '../../../assets/research.svg';
import visual from '../../../assets/visual.svg';
import performing from '../../../assets/performing.svg';
import music from '../../../assets/music.svg';
import physEd from '../../../assets/physEd.svg';
import robotics from '../../../assets/robotics.svg';
import { ActivityMap } from './Activities';

export function getIcon(activity: string): string {
  switch (activity) {
    case ActivityMap.Academic['Creative Writing']:
      return written;
    case ActivityMap.Academic.Debate:
      return languages;
    case ActivityMap.Academic.Journalism:
      return journalism;
    case ActivityMap.Academic.Mentorship:
      return mentorship;
    case ActivityMap.Athletic.Coaching:
      return coaching;
    case ActivityMap.Athletic['Individual Sports']:
      return individual;
    case ActivityMap.Athletic['Outdoor Activities']:
      return outdoors;
    case ActivityMap.Athletic['Team Sports']:
      return physEd;
    case ActivityMap.Arts.Dance:
      return dance;
    case ActivityMap.Arts.Drama:
      return performing;
    case ActivityMap.Arts.Music:
      return music;
    case ActivityMap.Arts.Visual:
      return visual;
    case ActivityMap.Community.Clubs:
      return clubs;
    case ActivityMap.Community.Environment:
      return environment;
    case ActivityMap.Community['Student Gov']:
      return leadership;
    case ActivityMap.Community.Volunteer:
      return volunteer;
    case ActivityMap.Scientific.Coding:
      return computer;
    case ActivityMap.Scientific.Engineering:
      return engineering;
    case ActivityMap.Scientific.Research:
      return research;
    case ActivityMap.Scientific.Robotics:
      return robotics;
    default:
      return '';
  }
}
