import './UserInfo.scss';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import Introduction from './Introduction/Introduction';
import TestScores from './TestScores/TestScores';
import Activities from './Activities/Activities';
import FavoriteClasses from './FavoriteClasses/FavoriteClasses';
import CostAndGeography from './CostAndGeography/CostAndGeography';
import { RoutesEnum } from '../routes';
import introduction from '../../assets/introduction.svg';
import introductionWhite from '../../assets/introduction-white.svg';
import testScores from '../../assets/test-scores.svg';
import testScoresWhite from '../../assets/test-scores-white.svg';
import interestsAndActivities from '../../assets/interests-and-activities.svg';
import interestsAndActivitiesWhite from '../../assets/interests-and-activities-white.svg';
import favoriteClasses from '../../assets/favorite-classes.svg';
import favoriteClassesWhite from '../../assets/favorite-classes-white.svg';
import costAndGeography from '../../assets/cost-and-geography.svg';
import costAndGeographyWhite from '../../assets/cost-and-geography-white.svg';
import { auth, db, dbUser } from '../../firebase';
import { collection, getDocs, limit, query, where } from 'firebase/firestore';
import { User } from '../../types';

export async function getUser() {
  const userEmail = auth.currentUser?.email;
  const queryUser = query(collection(db, dbUser), where('userInfo.email', '==', userEmail), limit(1));
  const doc = (await getDocs(queryUser)).docs[0];

  return { ...doc.data(), id: doc.id } as User;
}

export const UserInfoRoutes = {
  Introduction: '/introduction',
  TestScores: '/test-scores',
  Activities: '/activities',
  FavoriteClasses: '/favorite-classes',
  CostAndGeography: '/cost-and-geography'
};

function UserInfo() {
  const location = useLocation();
  const navigate = useNavigate();

  const getTitle = () => {
    switch (location.pathname) {
      case `${RoutesEnum.UserInfo}${UserInfoRoutes.Introduction}`:
        return 'Introduction';
      case `${RoutesEnum.UserInfo}${UserInfoRoutes.TestScores}`:
        return 'Test Scores';
      case `${RoutesEnum.UserInfo}${UserInfoRoutes.Activities}`:
        return 'Interests And Activities';
      case `${RoutesEnum.UserInfo}${UserInfoRoutes.FavoriteClasses}`:
        return 'Favorite Classes';
      case `${RoutesEnum.UserInfo}${UserInfoRoutes.CostAndGeography}`:
        return 'Cost and Geography';
      default:
        return 'Introduction';
    }
  };

  const getClassNames = (pathname: string) => {
    return `d-flex justify-content-center align-items-center navigation-icon ${
      location.pathname === pathname ? 'selected' : ''
    }`;
  };

  return (
    <span className="user-info">
      <div className="header">
        <h1>{getTitle()}</h1>
        <div className="navigation-panel d-flex justify-content-center align-items-center">
          <div
            className={getClassNames(`${RoutesEnum.UserInfo}${UserInfoRoutes.Introduction}`)}
            onClick={() => navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.Introduction}`)}>
            {location.pathname === `${RoutesEnum.UserInfo}${UserInfoRoutes.Introduction}` ? (
              <img alt="Introduction selected" src={introductionWhite} />
            ) : (
              <img alt="Introduction" src={introduction} />
            )}
          </div>
          <div
            className={getClassNames(`${RoutesEnum.UserInfo}${UserInfoRoutes.TestScores}`)}
            onClick={() => navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.TestScores}`)}>
            {location.pathname === `${RoutesEnum.UserInfo}${UserInfoRoutes.TestScores}` ? (
              <img alt="Test Scores selected" src={testScoresWhite} />
            ) : (
              <img alt="Test Scores" src={testScores} />
            )}
          </div>
          <div
            className={getClassNames(`${RoutesEnum.UserInfo}${UserInfoRoutes.Activities}`)}
            onClick={() => navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.Activities}`)}>
            {location.pathname === `${RoutesEnum.UserInfo}${UserInfoRoutes.Activities}` ? (
              <img alt="Interests And Activities selected" src={interestsAndActivitiesWhite} />
            ) : (
              <img alt="Interests And Activities" src={interestsAndActivities} />
            )}
          </div>
          <div
            className={getClassNames(`${RoutesEnum.UserInfo}${UserInfoRoutes.FavoriteClasses}`)}
            onClick={() => navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.FavoriteClasses}`)}>
            {location.pathname === `${RoutesEnum.UserInfo}${UserInfoRoutes.FavoriteClasses}` ? (
              <img alt="Favorite Classes selected" src={favoriteClassesWhite} />
            ) : (
              <img alt="Favorite Classes" src={favoriteClasses} />
            )}
          </div>
          <div
            className={getClassNames(`${RoutesEnum.UserInfo}${UserInfoRoutes.CostAndGeography}`)}
            onClick={() => navigate(`${RoutesEnum.UserInfo}${UserInfoRoutes.CostAndGeography}`)}>
            {location.pathname === `${RoutesEnum.UserInfo}${UserInfoRoutes.CostAndGeography}` ? (
              <img alt="Cost And Geography selected" src={costAndGeographyWhite} />
            ) : (
              <img alt="CostAndGeography" src={costAndGeography} />
            )}
          </div>
        </div>
      </div>
      <div className="content">
        <Routes>
          <Route path="/" element={<Navigate to={`${RoutesEnum.UserInfo}${UserInfoRoutes.Introduction}`} />} />
          <Route path={UserInfoRoutes.Introduction} element={<Introduction />} />
          <Route path={UserInfoRoutes.TestScores} element={<TestScores />} />
          <Route path={UserInfoRoutes.Activities} element={<Activities />} />
          <Route path={UserInfoRoutes.FavoriteClasses} element={<FavoriteClasses />} />
          <Route path={UserInfoRoutes.CostAndGeography} element={<CostAndGeography />} />
        </Routes>
      </div>
    </span>
  );
}

export default UserInfo;
