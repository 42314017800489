import firebase from 'firebase/compat/app';
import FirebaseError = firebase.FirebaseError;

export function validateEmail(email: string): string {
  if (!email) {
    return 'Missing email';
  } else if (!isValidEmail(email)) {
    return 'Invalid email';
  }
  return '';
}

export function validatePassword(password: string): string {
  if (!password) {
    return 'Missing password';
  }
  return '';
}

export function isValidEmail(email: string): boolean {
  return /\S+@\S+\.\S+/.test(email);
}

export function getFirebaseErrorText(error: FirebaseError): string {
  let errorText = error.code.split('/')[1].replaceAll('-', ' ');
  return errorText.charAt(0).toUpperCase() + errorText.slice(1);
}
