import { Classes } from './FavoriteClasses';
import './ClassChip.scss';
import { useEffect, useState } from 'react';
import { getIcon } from './Icons';

export interface ClassChipProps {
  classText: string;
  overrideSelected?: boolean;
  selected?: boolean;
  chipSize?: ChipSize;
  onClick?: (selected: boolean) => void;
}

export enum ChipSize {
  Small = 'small',
  Default = '',
  Big = 'big'
}

function ClassChip({ classText, overrideSelected = false, selected = false, chipSize = ChipSize.Default, onClick }: ClassChipProps) {
  const [isSelected, setIsSelected] = useState(selected);
  const getSectionName = () => {
    let result = '';
    Object.keys(Classes).forEach((section) => {
      if (result) {
        return;
      }
      Object.keys((Classes as any)[section]).forEach((classNameText) => {
        if (result) {
          return;
        }
        if ((Classes as any)[section][classNameText] === classText) {
          result = section.toLowerCase();
        }
      });
    });

    return result;
  };

  useEffect(() => {
    setIsSelected(selected);
  }, []);

  const clickHandler = () => {
    if (onClick) {
      setIsSelected(!isSelected);
      onClick(!isSelected);
    }
  };

  return getIcon(classText) ? (
    <div
      onClick={clickHandler}
      className={`d-flex justify-content-between class-chip ${getSectionName()} ${
        overrideSelected || isSelected ? 'selected' : ''
      } ${chipSize}`}>
      {chipSize !== ChipSize.Small && <span>{classText}</span>}
      <img alt="logo" src={getIcon(classText)} />
    </div>
  ) : (
    <></>
  );
}

export default ClassChip;
