import fav from '../assets/fav.svg';
import favFilled from '../assets/fav-filled.svg';
import React, { useEffect, useState } from 'react';
import './Program.scss';
import { doc, updateDoc } from 'firebase/firestore';
import { db, dbUser } from '../firebase';
import { User } from '../types';
import info from '../assets/info.svg';
import infoDark from '../assets/info-dark.svg';
import collapseArrowRight from '../assets/collapse-arrow-right.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutesEnum } from '../pages/routes';
import { getStateCode } from '../common/buildProgram';
import { logButtonClick, pageNameByUrl } from '../common/analytics';
import { getFieldsOfStudy, FieldOfStudyWithClasses } from '../common/storage';
import ClassChip, { ChipSize } from '../pages/UserInfo/FavoriteClasses/ClassChip';
import { getIcon } from '../pages/UserInfo/FavoriteClasses/Icons';

export interface ProgramModel {
  unique: string;
  school: string;
  state: string;
  degree: string;
  fieldOfStudy: string;
  programScore: number;
  pathScore: string;
  matchScore: number;
  price: string;
  incomePresented: number;
  gradCount: string;
  schoolType: string;
  interestInMajorScore: string;
  yearlyInfo: YearlyInfo;
  incomeRank: number;
  graduationScore: number;
  repaymentScore: number;
  admissionLikelihood: string,
}

interface YearlyInfo {
  [year: string]: {
    medianIncome: number;
    estimatedIncome: number;
    employment: number;
    schoolAverageIncome: number;
  };
}

interface ProgramProps {
  program: ProgramModel;
  user: User;
}

function Program({ program, user }: ProgramProps) {
  const [saved, setSaved] = useState(!!user?.savedPrograms?.find((pr) => pr.unique === program.unique) || false);
  const [major, setMajor] = useState({} as FieldOfStudyWithClasses);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getFieldsOfStudy().then((res) => {
      setMajor(
        res.find((m) => m.fieldOfStudy.replaceAll('.', '') === program.fieldOfStudy.replaceAll('.', '')) as FieldOfStudyWithClasses
      );
    });
  }, []);

  const pageName = pageNameByUrl(location.pathname);
  const updateSave = async (event: React.MouseEvent<HTMLImageElement>, saved: boolean) => {
    logButtonClick('save_program', pageName, program.unique);
    event.stopPropagation();
    setSaved(saved);

    if (saved) {
      if (user.savedPrograms) {
        user.savedPrograms.push(program);
      } else {
        user.savedPrograms = [program];
      }
    } else {
      user.savedPrograms = user.savedPrograms.filter((pr) => pr.unique !== program.unique);
    }
    await updateDoc(doc(db, dbUser, user.id), {
      savedPrograms: user.savedPrograms
    });
  };

  function handleClickProgramDetails() {
    logButtonClick('program', pageName);
    navigate(RoutesEnum.ProgramDetails, {
      state: { program, user }
    });
  }

  return (
    <div className="program w-100" onClick={() => handleClickProgramDetails()}>
      <div className="header d-flex justify-content-between">
        <div className="study d-flex flex-column">
          <h2>
            {program.school} ({getStateCode(program.state)})
          </h2>
          <span>
            {program.fieldOfStudy} - {program.degree}
          </span>
        </div>
        <div className="icons d-flex align-items-center">
          {saved ? (
            <img alt="Saved" src={favFilled} onClick={(event) => updateSave(event, false)} />
          ) : (
            <img alt="Not saved" src={fav} onClick={(event) => updateSave(event, true)} />
          )}
          <img alt="Arrow" src={collapseArrowRight} />
        </div>
      </div>
      <div className="scores d-flex">
        <div
          className="program-score d-flex justify-content-between align-items-center"
          onClick={(event) => {
            logButtonClick('program_score', pageName);
            event.stopPropagation();
            navigate(RoutesEnum.ProgramScoreInfo);
          }}
        >
          <div className="d-flex align-items-center">
            <label className='label-bold'>Program Score</label>
            <img alt="Info"src={info} />
          </div>
          <span className="score">{program.programScore}</span>
        </div>
        <div
          className="path-score d-flex justify-content-between align-items-center"
          onClick={(event) => {
            logButtonClick('path_score', pageName);
            event.stopPropagation();
            navigate(RoutesEnum.PathScoreInfo);
          }}
        >
          <div className="d-flex align-items-center">
            <label className='label-bold'>Path Score</label>
            <img alt="Info"src={infoDark} />
          </div>
          <span className="score">{program.pathScore}</span>
        </div>
      </div>
      <div className="money d-flex">
        <div className="price d-flex align-items-center">
          <label>Median Income:</label>
          <span>{program.incomePresented}</span>
        </div>
        <div className="price d-flex align-items-center">
          <label>Median Cost of Attendance:</label>
          <span>{program.price}</span>
        </div>
      </div>
      <div className="classes d-flex justify-content-between align-items-center">
        {major && (
          <>
            <ClassChip
              classText={major.rank1}
              chipSize={ChipSize.Small}
              overrideSelected={!!user.userInfo.classes.find((cl) => cl.class === major.rank1)}
            />
            <ClassChip
              classText={major.rank2}
              chipSize={ChipSize.Small}
              overrideSelected={!!user.userInfo.classes.find((cl) => cl.class === major.rank2)}
            />
            <ClassChip
              classText={major.rank3}
              chipSize={ChipSize.Small}
              overrideSelected={!!user.userInfo.classes.find((cl) => cl.class === major.rank3)}
            />
            <ClassChip
              classText={major.rank4}
              chipSize={ChipSize.Small}
              overrideSelected={!!user.userInfo.classes.find((cl) => cl.class === major.rank4)}
            />
            <ClassChip
              classText={major.rank5}
              chipSize={ChipSize.Small}
              overrideSelected={!!user.userInfo.classes.find((cl) => cl.class === major.rank5)}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default Program;
