// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: 'AIzaSyBvUR0_gg_W7jaDzgJHAS_Da6wKR0CmXGw',
  authDomain: 'path-fe.firebaseapp.com',
  databaseURL: 'https://path-fe-default-rtdb.firebaseio.com',
  projectId: 'path-fe',
  storageBucket: 'path-fe.appspot.com',
  messagingSenderId: '538236675409',
  appId: '1:538236675409:web:a2aee303a22db2fc4c5f70',
  measurementId: 'G-1GNEKZK29E'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics();
export const dbUser = 'user';
export const dbSchools = 'schools';
export const dbStates = 'states';
export const dbFieldsOfStudy = 'fieldOfStudy';
export const sessionIdKey = 'sessionId';
export default app;
