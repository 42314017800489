import React, { useEffect, useState } from 'react';
import './Home.scss';
import Program, { ProgramModel } from '../../components/Program';
import chevron from '../../assets/chervon-right.svg';
import { NavLink } from 'react-router-dom';
import { HomeRoutesEnum } from '../Base';
import ProgramsPageWrapper from './ProgramsPageWrapper';
import { User } from '../../types';
import { logButtonClick, logPageView } from '../../common/analytics';

const pageName = 'home';

function Home({ user }: { user: User }) {
  const [programs, setPrograms] = useState([] as ProgramModel[]);
  const [savedPrograms, setSavedPrograms] = useState([] as ProgramModel[]);

  useEffect(() => {
    logPageView(pageName);
  }, []);

  useEffect(() => {
    setPrograms(user.topPrograms?.slice(0, 2) || []);
    setSavedPrograms(user.savedPrograms?.slice(0, 1) || []);
  }, [user]);

  return (
    <ProgramsPageWrapper user={user}>
      <div className="programs-container">
        <div className="title">
          <h1>Your Program Matches</h1>
          <label>Click on a program to learn more!</label>
        </div>
        <div className="programs">
          {user?.userInfo &&
            programs.map((program) => {
              return <Program key={program.unique} program={program} user={user}></Program>;
            })}
          <div className="see d-flex justify-content-end align-items-center">
            <NavLink to={HomeRoutesEnum.ProgramMatches} onClick={() => logButtonClick('top_matches', pageName)}>
              See top 20 matches <img alt="Chevron right" src={chevron} />
            </NavLink>
          </div>
        </div>
        {user?.userInfo && savedPrograms.length > 0 && (
          <div className="saved-programs d-flex flex-column">
            <div className="title">
              <h1> Your Saved Programs</h1>
            </div>
            <div className="programs">
              {savedPrograms.map((program) => {
                return <Program key={program.unique} program={program} user={user}></Program>;
              })}
              <div className="see d-flex justify-content-end align-items-center">
                <NavLink to={HomeRoutesEnum.SavedPrograms} onClick={() => logButtonClick('saved_programs', pageName)}>
                  See all saved programs <img alt="Chevron right" src={chevron} />
                </NavLink>
              </div>
            </div>
          </div>
        )}
      </div>
    </ProgramsPageWrapper>
  );
}

export default Home;
