import axios from 'axios';
import { ProgramModel } from '../components/Program';
import { dbStates } from '../firebase';
import { State } from '../pages/LoggedIn';
import { UserInfo } from '../types';
import { mapUserInfo } from './mapUser';

export interface ProgramApiModel {
  admission_prob_shown: string;
  field_study: string;
  school: string;
  degree: string;
  state: string;
  program_score: number;
  path_score: string;
  match_score: number;
  price: string;
  income_presented: number;
  grad_count: string;
  school_type: string;
  interest_in_class_score: string;
  median_income_1_yr: number;
  estimated_1yr_income: number;
  employment_1yr: number;
  income_1yr: number;
  median_income_2_yr: number;
  estimated_2yr_income: number;
  employment_2yr: number;
  income_2yr: number;
  median_income_3_yr: number;
  estimated_3yr_income: number;
  employment_3yr: number;
  income_3yr: number;
  income_rank_senior: number;
  graduation_score: number;
  repayment_rank: number;
}

export function buildProgramByResponse(data: ProgramApiModel) {
  if (data.field_study[data.field_study.length - 1] === '.') {
    data.field_study = data.field_study.slice(0, -1);
  }
  return {
    unique: `${data.school} ${data.field_study} ${data.degree}`,
    admissionLikelihood: data.admission_prob_shown,
    school: data.school,
    state: data.state,
    degree: data.degree,
    fieldOfStudy: data.field_study,
    programScore: data.program_score,
    pathScore: data.path_score,
    matchScore: data.match_score,
    price: data.price,
    incomePresented: data.income_presented,
    gradCount: data.grad_count,
    schoolType: data.school_type,
    interestInMajorScore: data.interest_in_class_score,
    yearlyInfo: {
      '1': {
        medianIncome: data.median_income_1_yr || 0,
        estimatedIncome: data.estimated_1yr_income,
        employment: data.employment_1yr * 100,
        schoolAverageIncome: data.income_1yr
      },
      '2': {
        medianIncome: data.median_income_2_yr || 0,
        estimatedIncome: data.estimated_2yr_income,
        employment: data.employment_2yr * 100,
        schoolAverageIncome: data.income_2yr
      },
      '3': {
        medianIncome: data.median_income_3_yr || 0,
        estimatedIncome: data.estimated_3yr_income,
        employment: data.employment_3yr * 100,
        schoolAverageIncome: data.income_3yr
      }
    },
    incomeRank: data.income_rank_senior * 100,
    graduationScore: data.graduation_score * 100,
    repaymentScore: data.repayment_rank * 100
  } as ProgramModel;
}

export async function fetchProgramSelections(userInfo : UserInfo) {
  const res = await axios.post('https://modelapi.path-technologies.com/api/get_program_selections', {
    ...mapUserInfo(userInfo),
    num_reach: 5,
    num_safety: 5,
    num_target: 10,
    random_seed: 1
  });

  return res.data.map((data: ProgramApiModel) => buildProgramByResponse(data));
}

export function getStateCode(stateName: string) {
  const states = JSON.parse(window.sessionStorage.getItem(dbStates) || '') as State[];
  return states.find((state) => state.state === stateName)?.abbreviation;
}
